<template>
    <div style="position: relative;">
        <v-scroll-y-reverse-transition>
            <div v-if="isCopied" class="copy-caption d-flex justify-end text-caption mr-5 primary--text">Copied!</div>
        </v-scroll-y-reverse-transition>
        <div class="tag d-flex mr-4 mb-4">
            <div class="text-body-2 secondary--text py-2 px-3">{{formattedLabel}}</div>
            <v-divider vertical></v-divider>
            <div
                class="text-body-2 primary--text py-2 px-3"
                ref="infoTag"
                style="cursor: pointer;"
                @click="copyInfoTags"
            >
                {{value}}
            </div>
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            isCopied: false,
        }
    },
    props: {
        label: {
            required: true
        },
        value: {
            required: true
        }
    },
    computed:{
        formattedLabel(){
            if(this.label=== 'ip'){
                return "IP"
            }

            return this.label && this.capitalizeText(this.label.trim().replace('_', ' '))
        }
    },
    methods: {
        async copyInfoTags() {
            try {
                await navigator.clipboard.writeText(this.value);
                this.isCopied = true;
                window.getSelection().selectAllChildren(this.$refs.infoTag);
                setTimeout(() => {
                    this.isCopied = false;
                    window.getSelection().removeAllRanges();
                }, 400);
            } catch (error) {
                console.error("Cannot copy info tag", error);
            }
        },
        capitalizeText(str){
            const arr = str.split(" ");
            for (var i = 0; i < arr.length; i++) {
                arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);
            }
            return arr.join(" ")
        }
    },
}
</script>

<style lang="scss" scoped>
.copy-caption {
    position: absolute;
    right: 0;
    top: -18px;
}

.tag{
    border-radius: 4px;
    background-color: var(--v-grey-lighten5);
    border: var(--v-grey-lighten2) solid thin;
}
</style>