// api.js
import axios from 'axios'


// Define your fetcher function using the Axios instance
const fetcher = async (url) => {
  try {
    const response = await axios.get(url)
    return response.data
  } catch (error) {
    console.error(error)
    throw error
  }
}

export default fetcher