<template>
    <div style="width: 100%; border-top: 1px solid var(--v-primary-lighten2)" class="d-flex align-center px-4 py-3">
        <!-- actual HTML audio element -->
        <audio ref="audioNode" :src="audioSrc" style="display: none"></audio>

        <div style="min-width: 260px">
            <div v-if="currentAnswer" class="d-flex align-center pl-4">
                <v-btn fab small elevation="0" color="primary lighten-4"
                    style="pointer-events: none">
                    <span class="text--primary">{{ currentAnswer.position }}</span>
                </v-btn>
                <div class="ml-4">
                    <respondent-info :answer="currentAnswer"></respondent-info>
                    <div class="text-caption mt-1 grey--text">
                        {{ formattAnswerDate(currentAnswer.created_at, false) }}
                    </div>
                </div>
            </div>
        </div>

        <div class="flex-grow-1 px-12">
            <!-- icons -->
            <div class="d-flex justify-center align-center">
                <v-btn icon :disabled="isFirstAudio" @click="setCurrentAudio(-1)">
                    <v-icon color="primary darken-1" large>mdi-skip-previous</v-icon>
                </v-btn>
                <v-btn icon large class="mx-5">
                    <v-icon color="primary darken-1" x-large @click="togglePlayPause">
                        {{ isPlaying ? 'mdi-pause-circle' : 'mdi-play-circle'}}
                    </v-icon>
                </v-btn>
                <v-btn icon :disabled="isLastAudio" @click="setCurrentAudio(1)">
                    <v-icon color="primary darken-1" large>mdi-skip-next</v-icon>
                </v-btn>
            </div>
            <!-- audio player seeker -->
            <div class="d-flex align-center justify-center">
                <div class="text-caption">{{ formattedCurrentTime }}</div>
                <v-slider v-model="currentTime" @input="seekProgressBar" hide-details class="mx-2 audio-slider" min="0"
                    :max="duration" style="max-width: 600px;" color="primary darken-1"></v-slider>
                <div class="text-caption">{{ formattedDuration }}</div>
            </div>
        </div>

        <div style="min-width: 260px" class="d-flex align-center">
            <v-menu offset-y top>
                <template v-slot:activator="{ on, attrs }">
                    <v-btn small text v-bind="attrs" v-on="on" @click.prevent class="pa-0" style="min-width: 45px" color="primary darken-1">
                        <div>{{ playbackSpeed }}x</div>
                    </v-btn>
                </template>
                <v-card>
                    <div class="text-caption px-2 pt-2">Playback Speed</div>
                    <v-card v-for="speed in [1, 1.25, 1.5, 1.75, 2]" :key="`${speed}-speed`" elevation="0"
                        class="rounded-0 pl-2 py-1" @click="updatePlaybackSpeed(speed)">
                        {{ speed }}x
                    </v-card>
                </v-card>
            </v-menu>
            <v-icon color="primary darken-1">{{ iconLabel }}</v-icon>
            <v-slider hide-details color="primary darken-1" min="0" max="100" v-model="volume" @input="updateVolume" class="audio-slider"></v-slider>
            <upgrade-wrapper :blocked="!enableAudioExport">
                <v-btn @click="download" :color="enableAudioExport?'primary darken-1':'deep-purple accent-3'" class="mr-4" icon>
                    <v-icon>mdi-download</v-icon>
                </v-btn>
            </upgrade-wrapper>
        </div>
    </div>
</template>

<script>
import RespondentInfo from './answer/RespondentInfo.vue';
import { mapGetters, mapMutations } from 'vuex';
import dayjs from 'dayjs';
import { downloadAudioFile } from '../utils/processAudio';
import { Crisp } from "crisp-sdk-web";
import UpgradeWrapper from './UpgradeWrapper.vue';

export default {
    data() {
        return {
            currentAnswer: null,
            audioSrc: null,
            duration: 0, // in 0.1 second units
            currentTime: 0, // in 0.1 second units
            intervalId: null,
            playbackSpeed: 1,
            volume: 50
        }
    },
    components: { RespondentInfo, UpgradeWrapper },
    computed: {
        ...mapGetters({
            audioPlayer: 'survey/audioPlayer',
            user: 'auth/user',
            features: 'auth/features',
        }),
        iconLabel() {
            if (this.volume === 0) return 'mdi-volume-off';
            if (this.volume < 33) return 'mdi-volume-low';
            if (this.volume < 67) return 'mdi-volume-medium';
            return 'mdi-volume-high';
        },
        isPlaying: {
            get() {
                return this.audioPlayer.isPlaying;
            },
            set(value) {
                this.setAudioPlayerPlaying(value);
            },
        },
        formattedDuration() {
            if (this.duration === 0) return '--:--';
            return this.formatTimeStamp(this.duration / 10);
        },
        formattedCurrentTime() {
            return this.formatTimeStamp(this.currentTime / 10);
        },
        isLastAudio() {
            const audioList = this.audioPlayer.audioList;
            if (!this.audioPlayer.currentAudioAnswer || audioList.length === 0) return false;
            return this.audioPlayer.currentAudioAnswer.id === audioList[audioList.length - 1].id;
        },
        isFirstAudio() {
            const audioList = this.audioPlayer.audioList;
            if (!this.audioPlayer.currentAudioAnswer || audioList.length === 0) return false;
            return this.audioPlayer.currentAudioAnswer.id === audioList[0].id;
        },
        showVisualization(){
            return this.user && this.user.has_visualization
        },
        enableAudioExport(){
           return this.features && this.features.audio_export
        }
    },
    watch: {
        'audioPlayer.currentAudioAnswer': function (value) {
            this.currentAnswer = value;
            this.startAudio(value);
        },
        'audioPlayer.isPlaying': function (value) {
            if (!value) this.pauseAudio();
        }
    },
    methods: {
        ...mapMutations({
            setAudioPlayerPlaying: 'survey/setAudioPlayerPlaying',
            resetAudioPlayback: 'survey/resetAudioPlayback',
            setCurrentAudio: 'survey/setCurrentAudio'
        }),
        handleExportToVideo(){
            this.$store.state.visualization.answer = this.audioPlayer.currentAudioAnswer
            this.$store.state.visualization.showExportEditorDialog = true
        },
        cleanInterval() {
            this.intervalId && clearInterval(this.intervalId);
            this.intervalId = null;
        },
        async startAudio(answer) {
            if (!answer.file_url) return;

            // if we are playing a new audio than the current one on the player
            if (!this.audioSrc || this.audioSrc !== answer.file_url) {
                this.audioSrc = answer.file_url;

                await new Promise(res => (this.$refs.audioNode.oncanplay = res));

                this.currentTime = 0;
                this.duration = Math.round(this.$refs.audioNode.duration * 10);
                this.$refs.audioNode.playbackRate = this.playbackSpeed;
            }

            // if the current audio is finished and we request a replay
            if (this.$refs.audioNode.duration === this.$refs.audioNode.currentTime) {
                this.$refs.audioNode.currentTime = 0;
                this.currentTime = 0;
            }
            this.cleanInterval();
            this.intervalId = setInterval(this.updatePlayer, 100);
            // Play the audio and handle any potential errors
            try {
                const playPromise = this.$refs.audioNode.play();
                if (playPromise !== undefined) {
                    playPromise.then(() => {
                        this.isPlaying = true;
                    }).catch(error => {
                        console.error("Audio playback was interrupted or failed to start:", error);
                    });
                }else{
                    this.isPlaying = true;
                }
            } catch (error) {
                console.error("Audio playback failed:", error);
                // You can handle the error here, e.g., show an error message to the user
            }
        },
        updateVolume(val) {
            if (this.$refs.audioNode) {
                this.$refs.audioNode.volume = val / 100;
            }
        },
        updatePlaybackSpeed(val) {
            if (this.$refs.audioNode) {
                this.$refs.audioNode.playbackRate = val;
                this.playbackSpeed = val;
            }
        },
        async togglePlayPause() {
            if (!this.$refs.audioNode || !this.audioSrc) return;
            if (this.isPlaying) {
                this.pauseAudio();
            } else {
                // if the current audio is finished and we request a replay
                if (this.$refs.audioNode.duration === this.$refs.audioNode.currentTime) {
                    this.$refs.audioNode.currentTime = 0;
                    this.currentTime = 0;
                }

                // Play the audio and handle any potential errors
                try {
                    const playPromise = this.$refs.audioNode.play();
                    if (playPromise !== undefined) {
                        playPromise.then(() => {
                            this.intervalId = setInterval(this.updatePlayer, 100);
                        }).catch(error => {
                            console.error("Audio playback was interrupted or failed to start:", error);
                        });
                    }else{
                        this.intervalId = setInterval(this.updatePlayer, 100);
                    }
                } catch (error) {
                    console.error("Audio playback failed:", error);
                    // You can handle the error here, e.g., show an error message to the user
                }
                
            }
            this.isPlaying = !this.isPlaying;
        },
        pauseAudio() {
            this.cleanInterval();
            this.$refs.audioNode.pause();
        },
        formatTimeStamp(numericTime) {
            const minute = String(Math.floor(numericTime / 60)).padStart(2, '0');
            const second = String(Math.round(numericTime % 60)).padStart(2, '0');
            return `${minute}:${second}`;
        },
        updatePlayer() {
            this.currentTime = Math.round(this.$refs.audioNode.currentTime * 10);
        },
        seekProgressBar(value) {
            if (isNaN(value)) return;
            this.$refs.audioNode.currentTime = value / 10;
        },
        formattAnswerDate(dateString) {
            return dayjs(dateString).format('YYYY-MM-DD hh:mma');
        },
        download() {
            downloadAudioFile(this.currentAnswer.file_url, this.currentAnswer.id);
        }
    },
    mounted() {
        Crisp.chat.hide()

        if (this.$refs.audioNode) {
            this.$refs.audioNode.onended = () => {
                this.isPlaying = false;
                this.currentTime = this.duration;
            };
        }

        if (this.audioPlayer.currentAudioAnswer) {
            this.currentAnswer = this.audioPlayer.currentAudioAnswer;
            this.startAudio(this.currentAnswer);
        }
    },
    beforeDestroy() {
        this.pauseAudio();
    },
    destroyed() {
        this.resetAudioPlayback();
        Crisp.chat.show()
    }
};
</script>

<style scoped lang="scss">
.audio-slider::v-deep {
    .v-slider:hover {
        cursor: pointer;
    }

    .v-slider__thumb {
        &::before, &::after {
            content: none;
        }
    }
}
</style>
