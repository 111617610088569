<template>
    <div>
        <list-items-per-day v-for="(answerPerDay, i) in answersByDate" :key="`day-group-${i}-${$route.params.questionId}`"
            :answers="answerPerDay.answers" :dateHeader="answerPerDay.date">
        </list-items-per-day>
    </div>
</template>

<script>
import ListItemsPerDay from '../regular/ListItemsPerDay.vue';
import dayjs from 'dayjs';

export default {
    name: 'RegularListItems',
    props: {
        answers: Array
    },
    components: { ListItemsPerDay },
    computed: {
        answersByDate() {
            const answers = this.answers.map((answer, i) => ({ ...answer, position: i + 1 }));
            const groupedAnswers = answers.group(({ created_at }) => dayjs(created_at).format('YYYY-MM-DD'));
            return Object.keys(groupedAnswers).map((dateString) => {
                return { date: dayjs(dateString).format('dddd, MMMM D, YYYY'), answers: groupedAnswers[dateString] };
            });
        }
    }
};
</script>
