var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',{staticClass:"d-flex align-top py-8",attrs:{"no-gutters":""}},[(_vm.answer && !_vm.answer.expires_at)?[_c('v-col',{staticStyle:{"max-width":"100px"}},[_c('v-btn',{staticClass:"ml-6",staticStyle:{"pointer-events":"none"},attrs:{"fab":"","small":"","elevation":"0","color":"grey lighten-4"}},[_c('span',{staticClass:"text--primary"},[_vm._v(_vm._s(_vm.answer.position))])])],1),_c('v-col',{staticClass:"mr-4 mt-1",staticStyle:{"min-width":"340px","max-width":"340px"}},[(['processing', 're-processing'].includes(_vm.answer.status))?_c('v-tooltip',{attrs:{"bottom":"","max-width":260},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-chip',_vm._g(_vm._b({attrs:{"label":"","small":"","color":"primary lighten-2"}},'v-chip',attrs,false),on),[_vm._v("Processing")])]}}],null,false,89480432)},[_c('span',[_vm._v("Some responses require a little more time to process. Refresh this page or check back soon 🙌")])]):(_vm.matrixType)?_c('matrix-transcript',{attrs:{"answer":_vm.answer}}):(_vm.textBasedAnswer)?_c('v-sheet',{staticClass:"pa-1",attrs:{"color":"primary lighten-5","rounded":""}},[(!_vm.longTranscript || _vm.expandTranscript)?_c('span',{staticClass:"text-body-2",staticStyle:{"color":"var(--v-primary-darken1)"}},[_vm._v(" "+_vm._s(_vm.answer.transcript)+" "),(_vm.expandTranscript)?_c('a',{staticClass:"text-decoration-underline",on:{"click":function($event){$event.preventDefault();(_vm.expandTranscript = !_vm.expandTranscript)}}},[_vm._v("collapse")]):_vm._e()]):_c('span',{staticClass:"text-body-2",staticStyle:{"color":"var(--v-primary-darken1)"}},[_vm._v(" "+_vm._s(_vm.formattedTranscript)+" "),_c('a',{staticClass:"text-decoration-underline",on:{"click":function($event){$event.preventDefault();(_vm.expandTranscript = !_vm.expandTranscript)}}},[_vm._v("read more")])])]):(_vm.contentOverflow)?_c('v-tooltip',{staticClass:"mt-1",attrs:{"bottom":"","max-width":260},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-chip',_vm._g(_vm._b({attrs:{"color":"primary lighten-5"}},'v-chip',attrs,false),on),[_c('span',{staticClass:"answer-chip",staticStyle:{"color":"var(--v-primary-darken1)"}},[_vm._v(" "+_vm._s(_vm.answer.transcript)+" ")])])]}}],null,false,3180543650)},[_c('span',[_vm._v(_vm._s(_vm.answer.transcript))])]):_c('v-chip',{attrs:{"color":"primary lighten-5"}},[_c('span',{ref:"answerContent",staticClass:"answer-chip",staticStyle:{"color":"var(--v-primary-darken1)"}},[_vm._v(" "+_vm._s(_vm.answer.transcript)+" ")])])],1),_c('v-col',{staticClass:"mt-2"},[_c('div',{staticClass:"grey--text text-caption"},[_vm._v(_vm._s(_vm.formattAnswerDate(_vm.answer.created_at, false)))])]),_c('v-col',{staticClass:"text-center mt-2"},[_c('respondent-info',{attrs:{"answer":_vm.answer}})],1),_c('v-col',{staticStyle:{"max-width":"100px"}},[_c('v-btn',{attrs:{"icon":""},on:{"click":_vm.handleLike}},[(_vm.liked)?_c('v-icon',{attrs:{"color":"red"}},[_vm._v("mdi-heart")]):_c('v-icon',[_vm._v("mdi-heart-outline")])],1)],1)]:[_c('v-col',{staticStyle:{"max-width":"100px"}},[_c('v-btn',{staticClass:"ml-6",staticStyle:{"pointer-events":"none"},attrs:{"fab":"","small":"","elevation":"0","color":"grey lighten-4"}},[_c('span',{staticClass:"text--primary"},[_vm._v(_vm._s(_vm.answer.position))])])],1),_c('v-col',{staticClass:"mr-4 mt-1",staticStyle:{"min-width":"340px","max-width":"340px"}},[_c('v-chip',{staticClass:"mt-2",attrs:{"small":"","elevation":"0","color":"deep-orange lighten-1","dark":"","label":""}},[_c('v-icon',{attrs:{"small":"","left":""}},[_vm._v(" mdi-lock-outline ")]),_vm._v(" expires "+_vm._s(_vm.$date(_vm.answer.expires_at).fromNow())+" ")],1)],1),_c('v-col',{staticClass:"mt-2"},[_c('div',{staticClass:"grey--text text-caption"},[_vm._v(_vm._s(_vm.formattAnswerDate(_vm.answer.created_at, false)))])]),_c('v-col',[_c('v-dialog',{attrs:{"width":"500"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mx-6",attrs:{"text":"","elevation":"0","color":"primary"}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-lock-open-variant-outline")]),_vm._v(" Unlock ")],1)]}}])},[_c('v-card',{staticClass:"pa-8"},[_c('locked-response',{attrs:{"expiresAt":_vm.answer.expires_at,"submissionCount":_vm.answer.submission_count_current_period}})],1)],1)],1)]],2)}
var staticRenderFns = []

export { render, staticRenderFns }