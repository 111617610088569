<template>
    <div class="flex-grow-1">
        <div class="d-flex align-center py-2 px-4 rounded-lg fill-width primary lighten-5 primary--text text--darken-1">
            <span class="text-body-2">{{processing? 'The transcript is processing. Please check back in a few minutes or refresh the page to see the updated transcript.':'Regenerate transcript and clear edits'}}</span>
            <v-spacer></v-spacer>
            <v-btn v-if="!processing" :loading="regeneratingTranscript" :disabled="regeneratingTranscript" small color="primary darken-1" icon @click="reprocessTranscription">
                <v-icon >mdi-refresh</v-icon>
            </v-btn>
        </div>
        <v-fade-transition hide-on-leave>
            <div v-if="(!regeneratingTranscript && answer && !processing)" class="d-flex flex-wrap py-4">
                <template  v-for="(wordItem, i) in words">
                    <span class="mr-1 mb-1 text-field" :data-value="editingText" v-if="(editingIndex ===i)" :key="`${i}-${wordItem.startTime}-${wordItem.startTime}-edit`">
                        <input @change="handleChange" :ref="`input-${i}`" @blur="handleInputBlur" type="text" v-model="wordItem.word" />
                    </span>
                    <v-chip :ref="`chip-${i}`" v-else @click="handleChipClick(i)" label color="grey lighten-4" class="mr-1 mb-1 token" :key="`${i}-${wordItem.startTime}-${wordItem.startTime}`">
                        {{wordItem.word}}
                    </v-chip>
                </template>
            </div>
            <v-skeleton-loader
                v-else
                class="py-4"
                type="paragraph@2"
            ></v-skeleton-loader>
        </v-fade-transition>
    </div>
</template>

<script>
import { mapActions, mapMutations } from 'vuex'
export default {
    data(){
       return {
            inputWidth: 60,
            editingIndex: null,
            regeneratingTranscript: false
        }
    },
    methods:{
        ...mapActions({
            updateAnswerWords: 'survey/updateAnswerWords',
            regenerateTranscription: 'survey/regenerateTranscription'
        }),
        ...mapMutations(['showSnackBar']),
        handleInputBlur(){
            this.editingIndex= null
        },
        handleChipClick(tokenIndex){
            this.editingIndex = tokenIndex
            if(this.$refs[`chip-${tokenIndex}`]){
                this.inputWidth = this.$refs[`chip-${tokenIndex}`].clientWidth
            }
            setTimeout(()=>{
                if(this.$refs[`input-${tokenIndex}`] && this.$refs[`input-${tokenIndex}`][0]){
                    this.$refs[`input-${tokenIndex}`][0].focus()
                }
            }, 0);
        },
        async reprocessTranscription(){
            try {
                this.regeneratingTranscript = true
                const res = await this.regenerateTranscription({answerId: this.answer.id, resultId: this.answer.result_id, questionId: this.answer.question_id})
                if(res && res.data && res.data.answer){
                    this.$store.state.visualization.answer = res.data.answer
                    if(res.data.answer.status === 'complete'){
                        this.showSnackBar({
                            text: "Transcript processing is complete.", 
                            color: 'success', 
                            timeout: 2000
                        })
                    }
                }
                this.regeneratingTranscript = false
            } catch (error) {
                this.regeneratingTranscript = false
                this.showSnackBar({
                    text: "Something went wrong. Can't regenerate transcript.", 
                    color: 'error', 
                    timeout: 2000
                })
            }
            
        },

        async handleChange(){
            try {
                this.updateAnswerWords({answerId: this.answer.id, resultId: this.answer.result_id, questionId: this.answer.question_id, words: this.words})

            } catch (error) {
                console.error('transcript word change error', error)
                this.showSnackBar({
                    text: "Something went wrong. Can't update transcript words.", 
                    color: 'error', 
                    timeout: 2000
                })
            }
        }
        
    },
    computed:{
        answer(){
            return this.$store.state.visualization.answer
        },
        words(){
            return this.answer?.words || []
        },
        editingText(){
            if(this.editingIndex === null){
                return ''
            }
            return  this.words[this.editingIndex]?.word || ''
        },
        processing(){
           return !!this.answer && ['processing', 're-processing'].includes(this.answer.status)
        }
    },
}
</script>

<style lang="scss" scoped>
.text-field{
    position: relative;
    padding: 0px 11px;
    caret-color: var(--v-primary-base);
    box-sizing: border-box;
    border: 1px solid var(--v-primary-darken1);
    border-radius: 4px;
    font-size: 14px;
    input {
        position: absolute;
        top:0;
        left:0;
        width:100%;
        height:100%;
        font: inherit;
        padding: inherit;
        margin:0;
        appearance: none;
        border: none;
    }
    &:focus-within {
        outline: none;
        input:focus {
            outline: none;
        }
    }

    &::after {
        content: attr(data-value);
        visibility: hidden;
        white-space: pre-wrap;
    }
}
</style>