import axios from 'axios'
export default {
    listQuestions(surveyId) {
        return axios.get(`/api/builder/${surveyId}/questions`)
    },
    updateQuestionOrder(surveyId, questionId, newOrderNumber) {
        return axios.post(`/api/builder/${surveyId}/questions/${questionId}/order`, {newOrderNumber})
    },
    duplicateQuestion(surveyId, questionId) {
        return axios.post(`/api/builder/${surveyId}/questions/${questionId}/duplicate`)
    },
    deleteQuestion(surveyId, questionId) {
        return axios.delete(`/api/builder/${surveyId}/questions/${questionId}`)
    },
    createQuestion(surveyId, questionData) {
        return axios.post(`/api/builder/${surveyId}/questions`, {...questionData})
    },
    getQuestion(surveyId, questionId) {
        return axios.get(`/api/builder/${surveyId}/questions/${questionId}`)
    },

    updateQuestion(surveyId, questionId, questionData) {
        return axios.put(`/api/builder/${surveyId}/questions/${questionId}`, {...questionData})
    },

    changeQuestionType(surveyId, questionId, newType) {
        return axios.post(`/api/builder/${surveyId}/questions/${questionId}/change-type`, {newType})
    },

    patchForm(formId, properties){
        return axios.patch(`/api/forms/${formId}`, properties)
    },

    createDynamicVariable(surveyId, variableData) {
        return axios.post(`/api/builder/${surveyId}/dynamic-variables`, {...variableData})
    },

    updateDynamicVariable(surveyId, variableId, variableData) {
        return axios.put(`/api/builder/${surveyId}/dynamic-variables/${variableId}`, {...variableData})
    },

    deleteDynamicVariable(surveyId, variableId) {
        return axios.delete(`/api/builder/${surveyId}/dynamic-variables/${variableId}`)
    },

    listDynamicVariables(surveyId) {
        return axios.get(`/api/builder/${surveyId}/dynamic-variables`)
    },

    listFields(surveyId){
        return axios.get(`/api/builder/${surveyId}/question-variables`)
    },

    publishSurvey(surveyId) {
        return axios.post(`/api/builder/${surveyId}/publish`)
    },

    sendConfirmationTestEmail(surveyId, emailData) {
        return axios.post(`/api/builder/${surveyId}/send-test-submission-confirmation-email`, {...emailData})
    },
    updateSubmissionConfirmationEmailSettings(surveyId, settings) {
        return axios.put(`/api/builder/${surveyId}/submission-confirmation-email`, {...settings})
    },

    listTranslationStatuses(surveyId) {
        return axios.get(`/api/builder/${surveyId}/translation-statuses`)
    }
}