<template>
    <div class="text-h6 survey flex-grow-1">
        <v-hover v-if="!editToggle" v-slot="{ hover }">
            <v-card style="max-width: 230px;" outlined flat class="pa-2  text-truncate" :color="hover?'primary lighten-4':'transparent'"  @click="editToggle=true">
                {{ survey.name}}
            </v-card>
        </v-hover>
        <v-text-field 
            v-else
            placeholder="Type form name" 
            dense outlined hide-details 
            v-model="survey.name" 
            @change="handleSurveyNameChange"
            :rules="[v => !!v || 'Required.']"
            validate-on-blur
            @blur="handleBlur"
            autofocus
        >
        </v-text-field>
    </div>
</template>

<script>
import { mapActions } from 'vuex'
import BuilderApi from '../api/BuilderApi'
export default {
    data(){
        return {
            editToggle: false
        }
    },
    inject: ['survey', 'refreshSurvey'],
    methods: {
        ...mapActions({
            updateSurvey: 'survey/updateSurvey'
        }),

        handleBlur(){
            if(this.survey.name){
                this.editToggle = false
            }
        },

        async handleSurveyNameChange(val){
            try {
                
                if(val){
                    await BuilderApi.patchForm(this.survey.id, {name: val})
                    await this.refreshSurvey()
                }
            } catch (error) {
                console.error('error in handleSurveyNameChange', error)
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.ellipsis-text{
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
</style>