<template>
    <div v-if="['processing', 're-processing'].includes(answer.status)" class="d-flex mb-2">
        <v-spacer></v-spacer>
        <v-card  outlined max-width="80%" class="d-flex rounded-lg align-top pa-4">
            <v-tooltip  bottom
                :max-width="260">
                <template v-slot:activator="{ on, attrs }">
                    <v-chip label small color="primary lighten-2" v-bind="attrs"
                        v-on="on">Processing</v-chip>
                </template>
                <span>Some responses require a little more time to process. Refresh this page or check back
                    soon 🙌</span>
            </v-tooltip>     
        </v-card>
    </div>
    <div class="d-flex mb-2 flex-grow-1" v-else>
        <div class="d-flex flex-column flex-grow-1">
            <template v-for="file in files">
                <results-file-attachment-media-item :file="file" :key="file.url"></results-file-attachment-media-item>
            </template>
        </div>
        <v-avatar size="32" color="grey lighten-3" class="mt-1 ml-2">
            <v-icon v-if="!avatarUrl">mdi-account</v-icon>
            <img v-else :src="avatarUrl"/>
        </v-avatar>
    </div>
</template>

<script>
import RespondentInfo from '../RespondentInfo.vue';
import LockedResponse from '../../../views/dashboard/voiceform/results/LockedResponse.vue';
import ResultsFileAttachmentMediaItem from './ResultsFileAttachmentMediaItem.vue';

export default {
    name: 'ResultsFileAttachment',
    props: {
        answer: Object,
        avatarUrl: {
            type: String,
            default: null
        },
    },
    components: { RespondentInfo, LockedResponse, ResultsFileAttachmentMediaItem },
    data() {
        return {
            expandTranscript: false,
            contentOverflow: false,
            liked: this.answer.liked
        };
    },
    watch: {
        'answer.liked'(val) {
            this.liked = val;
        }
    },
    computed: {
        files(){
            return this.answer?.value?.value || [];
        }
    },
    mounted() {
        if(this.$refs.answerContent){
            this.contentOverflow = this.$refs.answerContent.clientWidth < this.$refs.answerContent.scrollWidth;
        }
    }
};
</script>

<style scoped lang="scss">
.answer-chip {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}
</style>