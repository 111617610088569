<template>
    <form-builder :fluid="fluid">
        <v-app-bar :style="`top: ${this.$vuetify.application.top}px;`"  height="48" fixed color="primary lighten-5 header px-0 py-0" elevation="0">
          <div class="d-flex flex-grow-1">
            <div class="d-flex justify-flex-start align-center flex-grow-1">
                <v-tabs v-if="tabs"  background-color="transparent" color="primary">
                    <v-tab 
                        v-for="tab in tabs"
                        class="text-subtitle-2 tab"
                        :key="tab.text"
                        :value="tab.value"
                        :to="tab.to"
                    >
                        <v-icon small class="mr-1" v-if="tab.icon">{{tab.icon}}</v-icon>
                        <v-chip v-if="tab.betaLabel" dark label small color="purple accent-3" class="pa-1 mr-1">Beta</v-chip>
                        {{tab.text}}
                    </v-tab>
                </v-tabs>
                <v-spacer></v-spacer>
                <v-menu offset-y >
                    <template v-slot:activator="{ on, attrs }">
                        <upgrade-wrapper :blocked="!enableExports">
                            <v-btn
                                :color="enableExports?'primary lighten-1 mr-2': 'deep-purple accent-3 mr-2'"
                                elevation="0"
                                v-bind="attrs"
                                v-on="on"
                                small
                                :disabled="loadingExport"
                                :loading="loadingExport"
                                :dark="!loadingExport"
                            >
                                <v-icon small left>{{enableExports?'mdi-export-variant':'mdi-diamond'}}</v-icon>
                                Export Form
                            </v-btn>
                        </upgrade-wrapper>
                    </template>
                    <v-list>
                        <v-list-item :disabled="!enableExports" @click.prevent="exportData('xlsx')">
                            <v-list-item-title>XLSX (Excel)</v-list-item-title>
                        </v-list-item>
                        <v-list-item :disabled="!enableExports" @click.prevent="exportData('csv')">
                            <v-list-item-title>CSV</v-list-item-title>
                        </v-list-item>
                    </v-list>
                </v-menu>
            </div>
          </div>
        </v-app-bar>
        <div class="pt-12"></div>
        <export-as-video-dialog v-model="visualization.showExportEditorDialog"></export-as-video-dialog>
        <slot></slot>
    </form-builder>
</template>

<script>
import { mapGetters } from 'vuex'
import { exportAllResponsesData } from '../api/survey'
import FormBuilder from './FormBuilder.vue';
import ExportAsVideoDialog from '../components/audio-vizualization/ExportAsVideoDialog.vue';
import { Crisp } from "crisp-sdk-web";
import UpgradeWrapper from '../components/UpgradeWrapper.vue';

export default {
    props: {
        fluid: {
            default: true,
            type: Boolean
        }
    },
    data(){
        return {
            loadingExport: false
        }
    },
    computed: {
        ...mapGetters({
            features: 'auth/features',
            user: 'auth/user'
        }),
        enableExports(){
            return !!this.features && this.features.data_export
        },
        visualization(){
            return this.$store.state.visualization
        },
        tabs(){
            if(this.$route.params.surveyId){
                return [
                    {
                        text: 'Insights', value: 'insights', to: {
                            name: 'dashboard.voiceform.results.insights',
                            params: {
                                surveyId: this.$route.params.surveyId
                            }
                        }
                    },
                    {
                        text: 'By question', value: 'by-question', to: {
                            name: 'dashboard.voiceform.results.questions',
                            params: {
                                surveyId: this.$route.params.surveyId,
                            }
                        }
                    },
                    {
                        text: 'By respondent', value: 'by-response', to: {
                            name: 'dashboard.voiceform.results.respondents',
                            params: {
                                surveyId: this.$route.params.surveyId,
                            }
                        }
                    },
                    {
                        text: 'Partial responses',
                        value: 'partial-responses',
                        icon: 'mdi-battery-70',
                        to: {
                            name: 'dashboard.voiceform.results.partial-responses',
                            params: {
                                surveyId: this.$route.params.surveyId,
                            }
                        }
                    },
                    ...(
                        process.env.VUE_APP_NEW_RESULT_PAGE === 'enabled'
                            ? [{
                                text: 'Likes', value: 'likes', icon: 'mdi-heart-outline', to: {
                                    name: 'dashboard.voiceform.results.likes',
                                    params: {
                                        surveyId: this.$route.params.surveyId,
                                    }
                                }
                            }]
                            : []
                    ),
                    ...(
                        this.user && this.user.has_visualization?
                        [{
                            text: 'Visualizations', value: 'visualizations', betaLabel: true, to: {
                                name: 'dashboard.voiceform.results.visualizations',
                                params: {
                                    surveyId: this.$route.params.surveyId,
                                }
                            }
                        }]: []
                    ),
                ]
            }
            return null
        }
    },
    components: { FormBuilder, ExportAsVideoDialog, UpgradeWrapper },
    methods: {
        async exportData(type){
            try {
                this.loadingExport = true
                await exportAllResponsesData(this.$route.params.surveyId, type)
                this.loadingExport = false
            } catch (error) {
                this.loadingExport = false
                console.error(`Failed to export survey ${type}`);
            }
        }
    },
    created(){
        Crisp.chat.show()
    }
    
}
</script>

<style scoped>
.tab{
    text-transform: capitalize;
}
.header{
    align-items: flex-start;
    /* top: 64px; */
}
</style>