<template>
    <v-slide-x-transition>
        <v-card
            class="pa-4 mb-6"
            outlined 
            :to="{
                path: `/dashboard/voiceform/${$route.params.surveyId}/results/respondents/${result.id}`,
            }"
            :color="active? 'primary': ''"
            :dark="active"
            :id="`responder-${result.id}`"
        >
            <v-hover class="d-flex flex-grow-1" v-slot="{ hover }">
                <div>
                    <div v-if="result.survey_count" class="d-flex align-center question-type-badge">
                        <div class="text-body-2">{{`#${result.survey_count}`}}</div>
                    </div>
                    <div class="text-caption flex-grow-1 px-4">
                        <div :class="active?'primary--text text--lighten-5':'grey--text text--darken-1'">
                            {{$date(result.created_at).format('MMM D, YYYY')}}</div>
                        <div v-if="!result.expires_at && responderName" class="mt-2 elipsis-text">{{responderName}}</div>
                        <div v-if="!result.expires_at && responderEmail" class="mt-2 elipsis-text">{{responderEmail}}</div>
                        <v-chip :class="['mt-2', {'deep-orange--text text--darken-4': active}]" small elevation="0" :color="active?'deep-orange lighten-4' :'deep-orange lighten-1'" dark label v-if="result.expires_at">
                            <v-icon small left>
                                mdi-lock-outline
                            </v-icon>
                            expires {{ $date(result.expires_at).fromNow() }}
                        </v-chip>
                    </div>
                    <v-menu offset-x>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn plain class="mx-n4" v-bind="attrs" @click.prevent v-on="on" icon>
                                <v-icon v-if="hover || (attrs &&  attrs['aria-expanded']==='true')">mdi-dots-vertical</v-icon>
                            </v-btn>
                        </template>
                        <v-list>
                            <v-list-item @click="$emit('copyLink', result)">
                                <v-list-item-title>Copy link</v-list-item-title>
                            </v-list-item>
                            <v-list-item @click="$emit('delete', result)">
                                <v-list-item-title>Delete result</v-list-item-title>
                            </v-list-item>
                        </v-list>
                    </v-menu>
                </div>
            </v-hover>
        </v-card>
    </v-slide-x-transition>
</template>

<script>
export default {
    props: {
        result: {
            required: true
        }
    },
    computed: {
        responderName() {
            return this.result && this.result.information_data && (this.result.information_data.name || this.result.information_data.first_name || this.result.information_data.last_name) || null
        },
        responderEmail(){
            return this.result && this.result.information_data && this.result.information_data.email || null
        },
        active(){
            return this.$route.params.resultId == this.result.id
        }
    },
    methods:{
        getInfoFromAnswer(type){
            const answer = this.result.answers.find(answer => answer.value && answer.value.type == type);
            return answer && answer.value && answer.value.value || null;
        }
    }
}
</script>

<style lang="scss" scoped>
.question-type-badge{
    width: 60px;
    height: 24px;
    min-width: 60px;
    justify-content: center;
    padding-left: 6px;
    padding-right: 6px;
    padding-top: 4px;
    padding-bottom: 4px;
    border-radius: 4px;

    background-color: var(--v-primary-lighten5);
    color: var(--v-primary-darken1);
}
.elipsis-text{
    max-width: 138px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}
</style>