<template>
    <v-row no-gutters>
        <v-col>
            <v-col>
                <span class="text-caption">Status</span>
            </v-col>
            <v-col class="text-body-2">
                <v-tooltip bottom :max-width="260">
                    <template v-slot:activator="{ on, attrs }">
                        <span v-bind="attrs" v-on="on">
                            <v-chip>Processing</v-chip>
                        </span>
                    </template>
                    <span>Some responses require a little more time to process. Refresh this page or check back soon 🙌</span>
                </v-tooltip>
            </v-col>
        </v-col>
        <v-col style="min-width: 140px;" md="1">
            <v-col>
                <span class="text-caption">Submitted on</span>
            </v-col>
            <v-col class="text-body-1">
                <span>{{createdDate}}</span>
            </v-col>
        </v-col>
        <v-col md="2">
            <v-col>
                <span class="text-caption">Name</span>
            </v-col>
            <v-col class="text-body-1 ">
                <span>{{name}}</span>
            </v-col>
        </v-col>
        <v-col md="2">
            <v-col>
                <span class="text-caption">Email</span>
            </v-col>
            <v-col class="text-body-1" style="overflow-wrap: break-word;">
                <span>{{email}}</span>
            </v-col>
        </v-col>
    </v-row>
</template>

<script>
export default {
    name: "ProcessingListItem",
    props: {
        createdDate: {
            required: true
        },
        name: {
            type: String
        },
        email: {
            type: String
        }
    }
};
</script>
