// Reference to https://github.com/welefen/canvas2video

// Fix https://stackoverflow.com/questions/73685095/im-getting-error-while-building-the-project-in-vue-for-ffmpeg-wasm
const defaultOptions = {
    mimeType: 'video/webm;codecs=h264',
    outVideoType: 'mp4',
    transcodeOptions: '',
    concatDemuxerOptions: '-c:v copy -af apad -map 0:v -map 1:a -shortest'
};
export class Canvas2Video {
    constructor(config) {
        this.config = Object.assign({}, defaultOptions, config);
    }
    /**
     * start to record canvas stream
     */
    startRecord() {
        const deferred = {};
        deferred.promise = new Promise((resolve, reject) => {
            deferred.resolve = resolve;
            deferred.reject = reject;
        });
        this.deferred = deferred;
        const stream = this.config.canvas.captureStream(30);
        const recorder = new MediaRecorder(stream);
        if(this.config.audioTrack){
            stream.addTrack(this.config.audioTrack)
        }
        const data = [];
        recorder.ondataavailable = function (event) {
            if (event.data && event.data.size) {
                data.push(event.data);
            }
        };
        recorder.onstop = () => {
            this.availableData = data && !!data.length && data[0]
            this.deferred.resolve(new Blob(data));
        };
        recorder.start();
        this.recorder = recorder;
    }
    /**
     * stop to record canvas stream
     */
    stopRecord() {
        this.recorder.stop();
    }
    /**
     * get canvas stream url, created by URL.createObjectURL & Blob
     */
    async getStreamURL() {
        const blob = await this.deferred.promise;
        const { mimeType, audio, outVideoType } = this.config;
        const type = mimeType.split(';')[0].split('/')[1];
        if (type === outVideoType && !audio) {
            return URL.createObjectURL(blob);
        }
        // if (!window.FFmpeg) {
        //     const err = new Error('please load FFmpeg script file like https://unpkg.com/@ffmpeg/ffmpeg@0.7.0/dist/ffmpeg.min.js');
        //     return Promise.reject(err);
        // }
        // return this.convertVideo(blob);
    }
}

