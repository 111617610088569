<template>
    <div>
        <!-- used by partial responses -->
        <template v-if="useFormattedObject">
            <div class="mb-2" v-for="(row) in formattedData" :key="row[0].row_id">
                <div class="text-subtitle-2">
                    <v-icon>mdi-table-row</v-icon>{{row[0].row_title}}
                </div>
                <div class="text-body-2 mb-1" v-for="column in row" :key="column.col_id">
                    <v-icon small>mdi-table-column</v-icon>
                    <template>
                        <span>{{column.col_title}}</span> - 
                        <v-chip small label v-if="column.type === 'radio'">{{ column.value }}</v-chip>
                        <template v-else-if="column.type === 'checkbox'">
                            <v-chip class="mr-1" small label v-for="checkbox_value in column.value" :key="checkbox_value.value">{{ checkbox_value.value }}</v-chip>
                        </template>
                        <v-chip small label v-else-if="column.type === 'numeric'">{{ column.value }}</v-chip>
                        <v-chip small label v-else-if="column.type === 'text'">{{ column.value }}</v-chip>
                        <v-chip small label v-else-if="column.type === 'dropdown'">{{ column.value.value }}</v-chip>
                    </template>
                </div>
            </div>
        </template>
        <template v-else>
            <div class="mb-2" v-for="(row) in answerValue" :key="row.row_id">
                <div class="text-subtitle-2">
                    <v-icon>mdi-table-row</v-icon>{{row && row.row_title || row.row_id}}
                </div>
                <div class="text-body-2 mb-1" v-for="column in row.columns" :key="column.col_id">
                    <v-icon small>mdi-table-column</v-icon>
                    <template v-if="column.group_name">
                        <span>{{column.group_name}}</span> -
                        <template v-if="column.group_value">
                            <v-chip small label v-if="column.group_type === 'radio'">{{ column.group_value.value }}</v-chip>
                            <template v-else-if="column.group_type === 'checkbox'">
                                <v-chip class="mr-1" small label v-for="checkbox_value in column.group_value" :key="checkbox_value.value">{{ checkbox_value.value }}</v-chip>
                            </template>
                        </template>
                    </template>
                    <template v-else>
                        <span>{{column.col_title}}</span> - 
                        <v-chip small label v-if="column.type === 'numeric'">{{ column.value }}</v-chip>
                        <v-chip small label v-else-if="column.type === 'text'">{{ column.value }}</v-chip>
                        <v-chip small label v-else-if="column.type === 'dropdown'">{{ column.value.value }}</v-chip>
                    </template>
                </div>
            </div>
        </template>
    </div>
</template>

<script> 
export default {
    props: {
        answer: {
            required: true
        },
    },
    computed: {
        answerValue() {
            return this.answer?.value?.value || [];
        },
        useFormattedObject() {
            if(this.answer?.value?.value && !Array.isArray(this.answer?.value?.value)){
                return true;
            }

            return false
        },
        formattedData() {
            const data = this.answer?.value?.value || {};
            const formattedData = [];
            const rowIds = Object.keys(data);
            for (let i = 0; i < rowIds.length; i++) {
                const rowId = rowIds[i];
                const row = [];
                const colIds = Object.keys(data[rowId]);
                for (let j = 0; j < colIds.length; j++) {
                    const colId = colIds[j];
                    let value = data[rowId][colId];
                    
                    if(Array.isArray(value)&& value.length !== 0){
                        row.push({
                            row_id: rowId,
                            row_title: value[0]?.row_title,
                            col_id: colId,
                            col_title: colId,
                            value,
                            type: value[0]?.type
                        });
                        continue;
                    }
                    row.push({
                        row_id: rowId,
                        row_title: value?.row_title,
                        col_id: colId,
                        col_title: ['radio', 'checkbox'].includes(value?.type)?colId:value?.col_title,
                        value: value?.type === 'checkbox'? value :value?.value,
                        type: value?.type
                    });
                }
                formattedData.push(row);
            }
            return formattedData;
        }
    }
}
</script>