<template>
    <div class="container-card" :style="{ padding, height, margin }">
        <slot></slot>
    </div>
</template>

<script>
export default {
    name: "ContainerCard",
    props: {
        padding: {
            type: String,
            default: "3rem",
        },
        margin: {
            type: String,
            default: "0",
        },
        height: {
            type: String,
            default: "min-content",
        },
    },
    data: function () {
        return {};
    },
};
</script>

<style lang="scss" scoped>
.container-card {
    background-color: var(--white);

    border-radius: 2rem;
    justify-items: center;
    align-content: center;
    overflow: hidden;

    box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%),
        0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);
}
</style>

