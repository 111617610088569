<template>
    <div class="d-flex flex-column align-center">
        <img src="https://cdn.voiceform.com/media/waiting-illustration.svg" style="height: 300px" height="300px" />
        <div class="text-subtitle-1 mt-8">Waiting for answers</div>
        <div class="text-body-1 mt-6">Don't forget to share your voiceform!</div>
        <div v-if="survey" class="d-flex mt-6">
            <v-text-field
                :value="sharableLink"
                outlined
                readonly
                dense
                ref="linkInput"
                v-on:focus="handleLinkInputFocus" 
            >
            </v-text-field>
            <v-btn @click="copyLink" :color="copiedLink?'primary':'secondary'" class="ml-1" height="40" elevation="0">{{copiedLink?'Copied':'Copy'}}</v-btn>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
    data(){
        return {
            copiedLink: false,
        }
    },
    computed: {
        ...mapGetters({ 
            survey: "survey/survey",
        }),

        showEmbeddingOptions(){
            return !!this.features && this.features.all_embedding_options
        },

        sharableLink(){
            let link = "";
            if (this.survey) {
                link = process.env.VUE_APP_APP_URL + `/to/${this.survey.hash}`;
            }
            
            return link;
        }
    },
    methods: {
        async copyLink() {
            try {
                this.$refs.linkInput.focus();
                await navigator.clipboard.writeText(this.sharableLink)
                this.copiedLink = true
                setTimeout(() => {
                    this.copiedLink = false
                }, 4000);
            } catch (error) {
                console.error("Cannot copy link", error);
            }
        },
        handleLinkInputFocus(event){
            event.target.select()
            navigator.clipboard.writeText(this.sharableLink)
        },
    }
}
</script>
