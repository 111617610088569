var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.answerItem.answers && _vm.answerItem.answers.length > 0)?_c('div',{key:_vm.answerItem.question.id,staticClass:"mb-8"},[_vm._l((_vm.answerItem.answers),function(questionAnswer){return [_c('response-details-question',{key:("question_text_" + (questionAnswer.id)),attrs:{"index":_vm.index,"question":_vm.answerItem.question,"loopIndex":questionAnswer.loop_index,"loop_item":questionAnswer.loop_item}}),(!['description-slide', 'file-upload'].includes(_vm.answerItem.question.type))?_c('div',{key:("question_answer_" + (questionAnswer.id)),staticClass:"mr-5"},[(_vm.audioType.includes(_vm.answerItem.question.type))?_c('audio-item',{attrs:{"answer":Object.assign({}, questionAnswer, 
                    {position: _vm.index+1,
                    // reuse the transcript from probes
                    transcript: _vm.probesGroupedByLoopIndex[questionAnswer.loop_index] &&  _vm.probesGroupedByLoopIndex[questionAnswer.loop_index].length &&  _vm.probesGroupedByLoopIndex[questionAnswer.loop_index][0].transcription && _vm.probesGroupedByLoopIndex[questionAnswer.loop_index][0].transcription.text || questionAnswer.transcript})}}):_c('regular-item',{key:questionAnswer.id,attrs:{"answer":Object.assign({}, questionAnswer, {position: _vm.index+1}),"questionProperties":_vm.answerItem && _vm.answerItem.question && _vm.answerItem.question.properties}})],1):(_vm.answerItem.question.type==='file-upload')?_c('div',{key:("question_answer_" + (questionAnswer.id))},[(questionAnswer)?_c('div',{staticClass:"mr-5"},[_c('results-file-attachment',{attrs:{"answer":questionAnswer,"question":_vm.answerItem.question}})],1):_vm._e()]):_vm._e(),_vm._l((_vm.probesGroupedByLoopIndex[questionAnswer.loop_index]),function(probe,index){return _c('div',{key:("probe_" + (probe.id)),staticClass:"mb-2"},[(index)?_c('div',{staticClass:"mr-5"},[(probe.answer)?[_c('audio-item',{attrs:{"disableLike":"","answer":{
                            file_url: probe.answer.url || null,
                            file_path: probe.answer.path || null,
                            transcript: probe.transcription && probe.transcription.text || null,
                            translation: probe.translation || null,
                            status: 'complete'
                        }}})]:_c('results-no-answer')],2):_vm._e(),(!probe.sufficient || probe.failed)?_c('probing-question',{attrs:{"failed":probe.failed,"followup":probe.followup,"followupTranslation":probe.followup_translation,"question":_vm.answerItem.question}}):_vm._e()],1)})]})],2):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }