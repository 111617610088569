<template>
  <div class="root">
    <div class="label-container">
      <p class="label">{{label}}</p>
    </div>
    <v-progress-linear
        height="24"
        rounded
        :color="`${color} lighten-3`"
        :value="percentage"
        :background-color="`${color} lighten-4`"
        :background-opacity="1"
      >
        {{dataRepresentation}}
      </v-progress-linear>
  </div>
</template>

<script>
export default {
    name: "PercentageBar",
    props: {
        label: {
          type: String,
          required: true
        },
        percentage: {
          type: Number,
          required: true
        },
        counterValue: {
          required: false
        },
        color: {
          type: String,
          default: 'primary'
        }
    },
    computed: {
      dataRepresentation(){
        if(this.counterValue){
          return `${this.counterValue} (${this.percentage}%)`
        }
        return `${this.percentage}%`
      }
    },
    data: function () {
        return {};
    },
};
</script>

<style lang="scss" scoped>
  .root{
    display: flex;
    // TODO: use typography class
    font-size: 14px;
    font-weight: 400;
    flex-direction: column;
    margin-bottom: 16px;
  }
  .label-container{
    display: block;
    margin-right: 8px;
  }
  .label{
    font-size: 12px;
    font-weight: 600;
    margin-bottom: 8px;
  }
</style>
