import axios from 'axios'

export default {

    /** Auth **/
    async forgotPassword(email) {
        return axios.post('/api/forgot-password', {email})
    },
    async resetPassword(params) {
        return axios.post('/api/reset-password', params)
    },

    async contactUs(message){
        return axios.post(`/api/contact-us`, message);
    },

    async sendEmailVerification(){
        return axios.get('/api/send-email-verification')
    }

}
