<template>
    <v-menu
        bottom
        min-width="274px"
        rounded
        offset-y
    >
        <template v-slot:activator="{ on }">
        <v-btn
            icon
            x-large
            v-on="on"
            class="ml-n2"
        >
            <v-avatar size="40" color="primary lighten-2">
                <img 
                    v-if="user.avatar_url"
                    :src="user.avatar_url"
                    alt="user avatar"
                >
                <span v-else-if="initials">{{initials}}</span>
                <img 
                    v-else
                    src="https://cdn.voiceform.com/media/default-avatar.png"
                    alt="user default avatar"
                >
            </v-avatar>
        </v-btn>
        </template>
        <v-card class="py-4">
            <div class="d-flex mx-4">
                <v-avatar size="40" color="primary lighten-2">
                    <img 
                        v-if="user.avatar_url"
                        :src="user.avatar_url"
                        alt="user avatar"
                    >
                    <span v-else-if="initials">{{initials}}</span>
                    <img 
                        v-else
                        src="https://cdn.voiceform.com/media/default-avatar.png"
                        alt="user default avatar"
                    >
                </v-avatar>
                <div class="ml-4">
                    <div v-if="name" class="text-subtitle-1">
                        {{name}}
                    </div>
                    <div v-if="email" class="mt-1 text-caption mt-1">
                        {{email}}
                    </div>
                </div>
            </div>
            <v-divider class="my-3"></v-divider>
            <v-list
                nav
                dense
            >
                <v-list-item-group
                    v-model="selectedItem"
                    color="primary"
                >
                    <v-list-item
                        v-for="(item, i) in items"
                        :to="item.routeName && { name: item.routeName}"
                        :href="item.href"
                        :target="item.target"
                        :key="i"
                    >
                        <v-list-item-icon>
                            <v-icon v-text="item.icon"></v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                            <v-list-item-title v-text="item.text"></v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                    
                </v-list-item-group>
            </v-list>
            <v-divider class="my-3"></v-divider>
            <v-btn @click="logout" text width="100%" class="justify-start px-6" color="error" large>
                <v-icon medium left>mdi-logout</v-icon>
                Log out
            </v-btn>
        </v-card>
    </v-menu>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
    data(){
        return {
            selectedItem: ''
        }
    },
    computed: {
        ...mapGetters({
            user: 'auth/user'
        }),
        name(){
            if(this.user){
                if(this.user.name){
                    return this.user.name
                }
                if(this.user.first_name && this.user.last_name){
                    return `${this.user.first_name} ${this.user.last_name}`
                }
            }
            return null
        },
        initials(){
            if(this.user){
                if(this.user.name){
                    if(this.user.name.includes(' ')){
                        const names = this.user.name.split(' ')
                        return `${names[0][0]}${names[names.length-1][0]}`
                    }else{
                        return null
                    }
                }
                if(this.user.first_name && this.user.last_name){
                    return `${this.user.first_name[0]}${this.user.last_name[0]}`
                }
            }
            return null
        },

        email(){
            if(this.user){
                return this.user.email
            }
            return null
        },
        showBilling(){
            return !!this.user && this.user.billing
        },
        items(){
            if(this.showBilling){
                return  [
                    {icon: 'mdi-account', text: 'Account', routeName: 'dashboard.account'},
                    {icon: 'mdi-credit-card-outline', text: 'Plan & Billing', routeName: 'dashboard.billing'},
                    {icon: 'mdi-open-in-new', text: 'Documentation', href: 'https://docs.voiceform.com', target: '_blank'}
                ]
            }

            return  [
                {icon: 'mdi-account', text: 'Account', routeName: 'dashboard.account'},
                {icon: 'mdi-open-in-new', text: 'Documentation', href: 'https://docs.voiceform.com', target: '_blank'}
            ]
        }
    },
    methods: {
        logout() {
            this.$auth
                .logout({})
                .then(() => {
                    window.location.href = "/login";
                })
                .catch(() => {
                    window.location.href = "/login";
                });
        },
    },
}
</script>