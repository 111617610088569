<template>
    <div>
        <div class="primary lighten-5">
            <v-stage ref="stage" :config="configKonva">
                <v-layer ref="layer">
                    <v-rect
                        :config="{
                            x: 0,
                            y: 0,
                            width: 540,
                            height: 540,
                            fill: 'white',
                            listening: false
                        }"
                    ></v-rect>
                    <v-circle
                        ref="outerCircle"
                        :config="{ ...configOuterCircle, stroke: primaryColor }"
                    ></v-circle>
                    <v-circle
                        :config="{ ...configCircle, fill: lightColor }"
                    ></v-circle>
                    <v-image
                        :config="{
                            ...iconConfig,
                            image: image
                        }"
                    />
                    <v-image
                        v-if="design.watermark"
                        :config="{
                            ...watermarkConfig,
                            image: watermark
                        }"
                    />
                    <v-text
                        :config="{
                            ...caption,
                            text: captionText
                        }"
                    ></v-text>

                    <v-text
                        :config="{
                            ...caption,
                            text: captionText
                        }"
                    ></v-text>
                </v-layer>
            </v-stage>
        </div>
        <audio
            @ended="handleAudioEnd"
            crossorigin="anonymous"
            ref="audio"
            style="display: hidden"
            :src="audioSrc"
            @canplay="handleAudioCanPlay"
            @error="handleAudioError"
        ></audio>
        <div class="d-flex mt-8">
            <v-card rounded elevation="0" class="d-flex flex-grow-1 align-center py-2 px-3 mr-3">
                <v-btn :disabled="!canPlay" @click="togglePlay" width="40" height="40" icon>
                    <v-icon>{{playing?'mdi-pause':'mdi-play'}}</v-icon>
                </v-btn>
                <div class="d-flex flex-grow-1 player-progress ">
                    <v-slider
                        hide-details
                        class="slider"
                        :value="((currentTime/durationTime)*100 || 0)"
                        @input="handleInput"
                        @mousedown="handleMouseDown"
                        @mouseup="handleMouseUp"
                    ></v-slider>
                </div>
                <div style="min-width: 80px" class="text-caption text-right ml-1">
                    {{formattedCurrentTime}}&nbsp;/&nbsp;{{formattedDuration}}
                </div>
            </v-card>
            <v-btn :loading="!canPlay && !audioError" :disabled="!canPlay" color="primary" elevation="0" height="56" @click="handleExport">
                <v-icon left>mdi-download</v-icon>
                Export
            </v-btn>
            <v-overlay color="primary" :value="isRecording">
                <v-card class="pa-8 d-flex flex-column text-center" light width="450" height="500">
                    <v-alert 
                        type="warning"
                        outlined
                        text
                        icon="mdi-alert"
                        class="mb-6"
                    >
                        While your video downloads, please do not switch browser tabs or close this window.
                    </v-alert>

                    <div class="text-h6 mb-6">Go grab a coffee, we've got this! ☕️</div>

                    <div class="text-body-1 mb-4">Video processing. Will be ready in a jiffy!</div>
                    <v-fade-transition>
                        <div v-if="serverProcessing">
                            <v-progress-circular indeterminate color="primary"></v-progress-circular>
                        </div>
                        <div v-else>
                            <v-progress-linear height="24"
                                rounded
                                color="primary"
                                :value="((currentTime/durationTime)*100 || 0)"
                            >
                            </v-progress-linear>
                            
                            <v-btn class="mt-4" @click="handleCancelExport" full-width text>
                                Cancel Export to MP4
                            </v-btn>
                        </div>
                    </v-fade-transition>
                </v-card>
            </v-overlay>
        </div>
    </div>
</template>

<script>
import Konva from "konva";
import { Canvas2Video } from "../../utils/canvasToVideo";
import { getRandomDecimal, getRandomInt } from "../../utils/numberOperations";
import VisualizationApi from '../../api/VisualizationApi'
import { pSBC } from "../../utils/invert-color";
import { mapMutations } from 'vuex';
export default {
    data() {
        return {
            globalAnimation: null,
            canPlay: false,
            audioError: false,
            configKonva: {
                width: 540,
                height: 540
            },
            configOuterCircle: {
                x: 2.5,
                y: 251.5,
                radius: 35,
                stroke: "rgba(0, 102, 255)",
                opacity: 0.5,
                strokeWidth: 0.86,
                offset: {
                    x: -89.5,
                    y: -89.5
                }
            },
            configCircle: {
                x: 25,
                y: 274,
                radius: 67,
                fill: "#e8efff",
                offset: {
                    x: -67,
                    y: -67
                }
            },
            image: null,
            reactionIcon: null,
            watermark: null,
            iconConfig: {
                x: 50.5,
                y: 299,
                width: 84,
                height: 84
            },
            watermarkConfig: {
                x: 25,
                y: 497,
                width: 148.5,
                height: 22.87,
                opacity: 0.3
            },
            caption: {
                fontFamily: "Poppins",
                fontSize: 16,
                lineHeight: 1.5,
                x: 25,
                y: 445.5,
                width: 490,
                opacity: 0.9,
                zIndex: 0
            },
            wavesHeights: [],
            context: null,
            source: null,
            analyser: null,
            playing: false,
            movingSlider: false,
            isRecording: false,
            serverProcessing: false,
            instance: null,
            timeoutId: null,
            audioTrack: null,
            currentTime: null,
            durationTime: null,
            coverImage: null,
        };
    },
    watch: {
        "$store.state.visualization.design.watermarkUrl": {
            immediate: true,
            handler(val) {
                // hack to avoid cache
                const src = new URL(val);
                src.searchParams.append('cors', Date.now());

                const image = new window.Image();
                image.crossOrigin = "anonymous";
                image.src = src.href;
                image.onload = () => {
                    // set image only when it is loaded
                    this.watermark = image;
                };
            }
        },
        "$store.state.visualization.design.iconUrl": {
            immediate: true,
            handler(val) {
                // hack to avoid cache
                const src = new URL(val || 'https://cdn.voiceform.com/media/audio-icons/mic.svg');
                src.searchParams.append('cors', Date.now());

                const image = new window.Image();
                image.crossOrigin = "anonymous";
                image.src = src.href;
                image.onload = () => {
                    // set image only when it is loaded
                    this.image = image;
                };
            }
        },
        "$store.state.visualization.design.reactionIcon": {
            immediate: true,
            handler(val) {
                // hack to avoid cache
                const src = new URL(val);
                src.searchParams.append('cors', Date.now());

                const image = new window.Image();
                image.crossOrigin = "anonymous";
                image.src = src.href;
                image.onload = () => {
                    // set image only when it is loaded
                    this.reactionIcon = image;
                };
            }
        }
    },
    computed: {
        answer(){
            return this.$store.state.visualization.answer
        },
        formattedCurrentTime(){
            if(this.canPlay && typeof this.currentTime === "number"){

                return this.$date.duration(this.currentTime).format('mm:ss')
            }
            return '--:--'
        },
        formattedDuration(){
            if(this.canPlay && typeof this.durationTime === "number"){
                return this.$date.duration(this.durationTime).format('mm:ss')
            }
            return '--:--'
        },
        design() {
            return this.$store.state.visualization.design;
        },
        primaryColor() {
            return this.design.primaryColor || "rgba(0, 102, 255, 1)";
        },
        captionText() {
            if (
                this.design.captionText &&
                this.design.captionText.length > 60
            ) {
                return this.design.captionText.slice(0, 60);
            }
            return this.design.captionText || "";
        },
        lightColor() {
            try {
                return pSBC(0.8, this.design.primaryColor, "c") || "#e8efff";
            } catch (error) {
                console.error("error in lightColor", error);
                return "#e8efff";
            }
        },
        audioSrc() {
            if(this.answer && this.answer.file_url){
                // hack to make sure the audio is not cached at this point
                const src = new URL(this.answer.file_url);
                src.searchParams.append('cors', Date.now());
                return src.href
            }
            return null;
        },
        wordGroups() {
            if (this.answer && this.answer.words) {
                const groups = [];
                this.answer.words.forEach(word => {
                    if(!word.word){
                        return
                    }
                    let startTime = Number(word.startTime.replace("s", ""))
                    let endTime = Number(word.endTime.replace("s", ""))
                    // hack to make sure there is no repeat in times
                    if(startTime === endTime){
                        endTime-=0.0001
                    }
                    const normalizedWord = {
                        ...word,
                        startTime,
                        endTime
                    };
                    if (
                        !groups.length ||
                        groups[groups.length - 1].transcript.length +
                            normalizedWord.word.length >
                            45
                    ) {
                        groups.push({
                            startTime: !groups.length? 0 : normalizedWord.startTime,
                            endTime: normalizedWord.endTime,
                            transcript: normalizedWord.word,
                            words: [{ ...normalizedWord }]
                        });
                    } else {
                        const lastGroup = groups[groups.length - 1];
                        lastGroup.transcript = `${lastGroup.transcript} ${normalizedWord.word}`;
                        lastGroup.endTime = normalizedWord.endTime;
                        lastGroup.words.push(normalizedWord);
                    }
                });
                return groups;
            }
            return [];
        }
    },
    methods: {
        ...mapMutations(['showSnackBar']),
        handleAudioCanPlay(){
            this.startAnimation()
            this.canPlay = true
        },
        handleAudioError(){
            this.audioError = true
            this.showSnackBar({
                text: "Whoops, looks like something went wrong, and we cannot load your recording. Try reopening this window or try cleaning your browser cache.",
                color: 'error', 
                timeout: 4000
            })

        },
        handleInput(val){
            if(!this.isRecording && (this.movingSlider || !this.playing) && this.$refs && this.$refs.audio && this.durationTime){
                this.$refs.audio.currentTime = (val * this.durationTime/100)/1000
            }
        },
        handleMouseDown(){
            this.movingSlider = true
            if(this.playing && this.$refs && this.$refs.audio){
                this.$refs.audio.pause()
            }
        },
        handleMouseUp(){
            this.movingSlider = false
            if(this.playing && this.$refs && this.$refs.audio){
                this.$refs.audio.play()
            }
        },
        togglePlay() {
            if(this.$refs.audio){
                if(this.playing){
                    this.playing = false
                    this.$refs.audio.pause()
                }else{
                    this.playing = true
                    this.$refs.audio.play()
                }
                
            }
        },

        animateReaction() {
            if (!this.design.reaction) {
                return;
            }
            if (this.timeoutId) {
                clearTimeout(this.timeoutId);
            }

            this.timeoutId = setTimeout(() => {
                const stage = this.$refs.stage.getNode();
                const layer = this.$refs.layer.getNode();
                var reactionIcon = new Konva.Image({
                    x: 100,
                    y: 360,
                    width: 45,
                    height: 45,
                    image: this.reactionIcon,
                    opacity: 0,
                    scale: { x: 0.5, y: 0.5 },
                });
                layer.add(reactionIcon);

                const scale = getRandomDecimal(0.5, 1);
                const randomX = getRandomInt(80, 120);
                const rotation = getRandomDecimal(-90, 90);


                var reactionTween = new Konva.Tween({
                    node: reactionIcon,
                    y: stage.height() + 30,
                    x: randomX,
                    rotation,
                    scaleX: scale,
                    scaleY: scale,
                    easing: Konva.Easings.EaseIn,
                    duration: 2,
                    opacity: 1,
                    onFinish: function() {
                        reactionIcon.destroy();
                    }
                });

                reactionTween.play();
            }, 30);
        },

        connectAnalyser(){
            const context =
                this.context ||
                new (window.AudioContext || window.webkitAudioContext)();
            this.context = context;

            const source =
                this.source ||
                this.context.createMediaElementSource(this.$refs.audio);
            this.source = source;

            var analyser = this.analyser || this.context.createAnalyser();
            this.analyser = analyser;

            source.connect(analyser);
            source.connect(this.context.destination);            
        },

        startAnimation() {
            var analyser = this.analyser

            analyser.fftSize = 1024;
            var bufferLength = analyser.frequencyBinCount;
            var frequencies = 15;
            var dataArray = new Uint8Array(bufferLength);

            var vm = this;

            const audio = this.$refs.audio;
            const duration = this.$refs.audio.duration;

            const wordSpace = 12;

            const layer = vm.$refs.layer.getNode();

            const anim = new Konva.Animation(function () {
                analyser.getByteFrequencyData(dataArray); // Copies the frequency data into dataArray
                // Results in a normalized array of values between 0 and 255
                // Before this step, dataArray's values are all zeros (but with length of 8192)

                // sync audio time with reactive state
                if(vm.currentTime !== audio.currentTime){
                    vm.currentTime = Math.round(audio.currentTime*1000)
                }

                // sync audio duration with reactive state
                if(vm.durationTime !== audio.duration){
                    vm.durationTime = Math.round(audio.duration*1000)
                }

                let sum = 0;

                for (let i = 0; i < bufferLength; i++) {
                    if (i < frequencies) sum += dataArray[i];
                }

                const average = sum / frequencies;
                const scale = (average / 255) * 2;
                let radius = vm.configOuterCircle.radius * (scale + 1);
                if (radius < 67) {
                    radius = 0;
                }

                if (radius > 89) {
                    radius = 89;
                }

                if (radius > 70) {
                    vm.animateReaction();
                }

                if(!vm.$refs.stage){
                    return
                }
                
                const stage = vm.$refs.stage.getNode();
                const layer = vm.$refs.layer.getNode();
                const circleNode = vm.$refs.outerCircle.getNode();
                circleNode.radius(radius);

                var audioWave = stage.find("#audio-waves")[0];

                const currentGroup = vm.wordGroups.find(
                    wordGroup =>
                        wordGroup.startTime <= audio.currentTime &&
                        wordGroup.endTime >= audio.currentTime
                );
                if (currentGroup) {
                    const currentWord = currentGroup.words.find(
                        word =>
                            word.startTime <= audio.currentTime &&
                            word.endTime >= audio.currentTime
                    );
                    const transcriptGroupId = `transcript-group-${currentGroup.startTime}`;
                    let renderedGroup = stage.find(`#${transcriptGroupId}`)[0];
                    const previousGroup = stage.find(
                        `.transcript-group`
                    )[0];
                    if (previousGroup) {
                        previousGroup.destroy();
                    }
                    renderedGroup = new Konva.Group({
                        id: transcriptGroupId,
                        name: "transcript-group",
                        x: 25,
                        y: 45.7
                    });

                    layer.add(renderedGroup);
                    let previousTextEndX = 0;
                    let previousTextStartY = 0;

                    currentGroup.words.forEach(word => {
                        const wordId = `word-${word.startTime}-${word.endTime}`;
                        let renderedWord = stage.find(`#${wordId}`)[0];
                        const isCurrentWord =
                            word.startTime <= audio.currentTime &&
                            word.endTime >= audio.currentTime;
                        if (!renderedWord || renderedWord.text() !== word.word ) {
                            renderedWord?.destroy();
                            renderedWord = new Konva.Text({
                                id: wordId,
                                fontFamily: "Poppins",
                                fontSize: 40,
                                lineHeight: 1.5,
                                x: previousTextEndX,
                                y: previousTextStartY,
                                text: word.word
                            });
                            const kWordWidth = renderedWord.width();
                            const kWordHeight = renderedWord.height();
                            let newEndX =
                                previousTextEndX + kWordWidth + wordSpace;
                            const hasWrap = newEndX > stage.width() - 100; // added margin for transcript group

                            // start current text from new line
                            if (hasWrap) {
                                renderedWord.x(0);
                                renderedWord.y(renderedWord.y() + kWordHeight);
                            }
                            previousTextEndX = hasWrap
                                ? kWordWidth + wordSpace
                                : newEndX;
                            previousTextStartY = renderedWord.y();
                            renderedGroup.add(renderedWord);
                        }

                        if (isCurrentWord) {
                            renderedWord.fill("white");
                            let cursor = stage.find(`#text-cursor`)[0];
                            const cursorX = renderedWord.x() - 6;
                            const cursorY = renderedWord.y() - 4;
                            const cursorWidth = renderedWord.width() + 12;
                            const cursorHeight = renderedWord.height() + 4;
                            if (!cursor) {
                                cursor = new Konva.Rect({
                                    id: "text-cursor",
                                    x: cursorX,
                                    y: cursorY,
                                    width: cursorWidth,
                                    height: cursorHeight,
                                    fill: vm.primaryColor,
                                    cornerRadius: 10
                                });

                                renderedGroup.add(cursor);
                                cursor.zIndex(0);
                                renderedWord.zIndex(1);
                            } else {
                                cursor.x(cursorX);
                                cursor.y(cursorY);
                                cursor.width(cursorWidth);
                                cursor.height(cursorHeight);
                                cursor.fill(vm.primaryColor);
                            }
                        } else if (currentWord) {
                            renderedWord.fill("black");
                        }
                    });
                }

                if (audioWave) {

                    let completion = audio.currentTime / duration;
                    if(completion>1) completion = 1
                    if(!completion || completion<0) completion = 0
                    audioWave.fillLinearGradientColorStops([
                        0,
                        vm.primaryColor,
                        completion,
                        vm.primaryColor,
                        completion,
                        vm.lightColor,
                        1,
                        vm.lightColor
                    ]);
                }
                
            }, layer);

            this.globalAnimation = anim
            anim.start();
        },

        handleExport() {
            try {
                if(this.source && this.context){
                    this.source.disconnect(this.context.destination)
                }
                this.$refs.audio.currentTime=0;
                const canvas = this.$refs.stage.$el.getElementsByTagName(
                    "canvas"
                )[0];
                this.isRecording = true;

                // generating cover image
                const stageNode = this.$refs.stage.getNode()
                if(stageNode){
                    this.coverImage = stageNode.toDataURL({ pixelRatio: 2 })
                }

                this.instance = new Canvas2Video({
                    canvas,
                    outVideoType: "webm"
                });

                this.instance.startRecord();

                this.$refs.audio.play();
            } catch (error) {
                console.error(error);
            }
        },
        async handleCancelExport(){
            this.playing= false
            this.$refs.audio.pause()
            this.$refs.audio.currentTime = 0;
            this.instance.stopRecord();
            this.serverProcessing = false;
            this.isRecording = false;
            if(this.source && this.context){
                this.source.connect(this.context.destination)
            }
        },
        async handleAudioEnd() {
            this.playing = false
            try {
                if (this.isRecording) {
                    this.instance.stopRecord();
                    this.serverProcessing = true
                    await this.instance.deferred.promise;
                    
                    if (this.instance.availableData) {
                        let formParams = new FormData();
                        formParams.append(
                            "canvas_blob",
                            this.instance.availableData
                        );

                        if(this.coverImage){
                            formParams.append("cover_image_data", this.coverImage);
                        }
                        
                        const res = await VisualizationApi.store(this.answer.id,formParams);
                        if(res.status === 200 && res.data && res.data.visualization){
                            this.serverProcessing = false
                            this.isRecording = false
                            this.playing = false
                            this.$refs.audio.currentTime = 0 
                            if(this.source && this.context){
                                this.source.connect(this.context.destination)
                            }
                            this.showSnackBar({
                                text: 'All good!', 
                                color: 'success', 
                                timeout: 2000
                            })

                            this.$store.state.visualization.showExportEditorDialog = false

                            setTimeout(()=>{
                                this.$router.push({
                                    name: 'dashboard.voiceform.results.visualizations',
                                    query: {
                                        id: res.data.visualization.id
                                    }
                                })
                            }, 300)
                        }
                    }
                }
            } catch (error) {
                this.showSnackBar({
                    text: "Something went wrong. Can't export the animation to video.", 
                    color: 'error', 
                    timeout: 2000
                })
                this.serverProcessing = false
            }
        },
        generateAudioWaves(){
            const layer = this.$refs.layer.getNode();

            const vm = this;

            const maxHeight = 62;
            const lowestHeight = 5;
            const itemWidth = 7.5;
            const numberOfItems = 24;
            const spaceBetweenItems = 7;
            const componentWidth = (spaceBetweenItems + itemWidth) * numberOfItems;

            let previousHeight = 0;

            const audioWaves = new Konva.Shape({
                sceneFunc: function(context, shape) {
                    context.beginPath();
                    for (let index = 0; index < numberOfItems; index++) {
                        const tempHight = previousHeight * 0.6;
                        const minHeight =
                            tempHight < lowestHeight ? lowestHeight : tempHight;
                        const cptOffset = itemWidth / 2;
                        const randomHeight =
                            vm.wavesHeights[index] ||
                            Math.random() * (maxHeight - minHeight) +
                                minHeight -
                                itemWidth; // removing width because of curvature
                        vm.wavesHeights[index] = randomHeight;
                        previousHeight = randomHeight;

                        const startX = (spaceBetweenItems + itemWidth) * index;
                        const startY = (maxHeight - randomHeight) / 2;
                        const endX = startX + itemWidth;
                        const endY = startY + randomHeight;

                        context.moveTo(startX, startY);
                        context.arc(
                            startX + cptOffset,
                            startY,
                            cptOffset,
                            Math.PI,
                            0
                        );
                        context.moveTo(endX, startY);
                        context.lineTo(endX, endY);
                        context.arc(
                            startX + cptOffset,
                            endY,
                            cptOffset,
                            0,
                            Math.PI
                        );
                        context.lineTo(startX, startY);
                    }
                    context.closePath();

                    // automatically fill shape from props and draw hit region
                    context.fillStrokeShape(shape);
                },
                id: "audio-waves",
                x: 178,
                y: 322,
                height: maxHeight,
                width: componentWidth,
                fillLinearGradientStartPoint: { x: 0, y: 0 },
                fillLinearGradientEndPoint: { x: componentWidth, y: 0 },
                fillLinearGradientColorStops: [
                    0,
                    vm.primaryColor,
                    0,
                    vm.primaryColor,
                    0,
                    vm.lightColor,
                    1,
                    vm.lightColor
                ]
            });
            layer.add(audioWaves);
        },
        handleVisibilityChange() {
            if (document.hidden && this.isRecording) {
                this.handleCancelExport()
                this.showSnackBar({
                    text: "The recording has been stopped due to page visibility change.", 
                    color: 'error', 
                    timeout: 4000
                })
            }
        }
    },
    mounted() {
        this.connectAnalyser()
        this.generateAudioWaves()
    },
    beforeUnmount(){
        if(this.globalAnimation){
            this.globalAnimation.stop()
        }
    },
    beforeMount() {
        document.addEventListener("visibilitychange", this.handleVisibilityChange);
        this.$once("hook:beforeDestroy", () => {
            document.removeEventListener("visibilitychange", this.handleVisibilityChange);
        })
    },
};
</script>

<style lang="scss" scoped>
.slider::v-deep .v-slider__thumb-container, .v-slider__track-background{
    transition: unset;
}
</style>
