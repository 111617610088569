<template>
    <audio-list-item v-if="isAudioAnswer && noPanels" :email="email" :name="name" :answer="answer" />
    <v-expansion-panels v-else-if="isAudioAnswer">
        <audio-list-item v-if="isAudioAnswer" :email="email" :name="name" :answer="answer" />
    </v-expansion-panels>
    <v-card outlined v-else-if="['processing', 're-processing'].includes(answer.status)" class="pa-4">
        <processing-list-item :createdDate="createdDate" :name="name" :email="email"></processing-list-item>
    </v-card>
    <v-card outlined v-else class="pa-4">
        <v-row  no-gutters>
            <v-col>
                <v-col>
                    <span class="text-caption">Answer</span>
                </v-col>
                <v-col class="text-body-2">
                    <span style="white-space: pre-line;" v-if="answer.transcript">{{answer.transcript}}</span>
                    <span v-else>N/A</span>
                </v-col>
            </v-col>
            <v-col style="min-width: 140px;" md="1">
                <v-col>
                    <span class="text-caption">Submitted on</span>
                </v-col>
                <v-col class="text-body-1">
                    <span>{{createdDate}}</span>
                </v-col>
            </v-col>
            <v-col md="2">
                <v-col>
                    <span class="text-caption">Name</span>
                </v-col>
                <v-col class="text-body-1 ">
                    <span>{{name}}</span>
                </v-col>
            </v-col>
            <v-col md="2">
                <v-col>
                    <span class="text-caption">Email</span>
                </v-col>
                <v-col class="text-body-1 ">
                    <span>{{email}}</span>
                </v-col>
            </v-col>
        </v-row> 
    </v-card>
</template>
<script>
import { VOICE_QUESTION, VOICE_RESPONSE } from '../../configs/questionTypes';
import KeywordsSentimentScoresList from '../keywords/KeywordsSentimentScoresList.vue';
import SentimentChip from '../sentiment/SentimentChip.vue';
import AudioListItem from './AudioListItem.vue';
import ProcessingListItem from './ProcessingListItem.vue';
export default {
  components: { SentimentChip, KeywordsSentimentScoresList, AudioListItem, ProcessingListItem },
    name: "ListItem",
    props: {
        answer: {
            required: true,
        },
        noPanels: {
            default: false
        },
        question: {
            required: true
        }
    },
    inject: ['result'],
    computed: {
        createdDate(){
            return this.$date(this.answer.created_at).format('YYYY-MM-DD');
        },
        name(){
            return this.getNameFromResult() || null
        },
        email(){
            return this.getEmailFromResult() || null
        },
        isAudioAnswer(){
            if(this.question){
               return [VOICE_RESPONSE, VOICE_QUESTION].includes(this.question.type)
            }
            return false
        }
    },
    methods:{
        getNameFromResult(){
            return this.result && this.result.information_data && (this.result.information_data.name || this.result.information_data.first_name || this.result.information_data.last_name) || ''
        },

        getEmailFromResult(){
            return this.result && this.result.information_data && this.result.information_data.email || ''
        },
    }
}
</script>
