<template>
    <v-card v-if="results" class="pa-4" outlined>
        <div class="text-subtitle-2 mb-4">
            Average Sentiment
        </div>
        <div v-if="enableSentiment" class="progress">
            <div class="progress-item positive" :style="`width: ${results.positive_percent}%`"></div>
            <div class="progress-item negative" :style="`width: ${results.negative_percent}%`"></div>
            <div class="progress-item neutral" :style="`width: ${results.neutral_percent}%`"></div>
        </div>
        <div v-else style="opacity: 60%;" class="progress">
            <div class="progress-item positive" style="width: 50%;"></div>
            <div class="progress-item negative" style="width: 30%;"></div>
            <div class="progress-item neutral" style="width: 25%;"></div>
        </div>
        <div v-if="enableSentiment" class="d-flex mt-4">
            <div class="title text-caption mr-2"><v-icon size="8" class="mr-2" color="success lighten-1">mdi-circle</v-icon>Positive <b>({{results.positive_percent}}%)</b></div>
            <div class="title text-caption mr-2"><v-icon size="8" class="mr-2" color="error lighten-1">mdi-circle</v-icon>Negative <b>({{results.negative_percent}}%)</b></div>
            <div class="title text-caption mr-2"><v-icon size="8" class="mr-2" color="grey">mdi-circle</v-icon>Neutral <b>({{results.neutral_percent}}%)</b></div>
        </div>
        <div v-else style="opacity: 60%;" class="d-flex mt-4">
            <div class="title text-caption mr-2"><v-icon size="8" class="mr-2" color="success lighten-1">mdi-circle</v-icon>Positive </div>
            <div class="title text-caption mr-2"><v-icon size="8" class="mr-2" color="error lighten-1">mdi-circle</v-icon>Negative </div>
            <div class="title text-caption mr-2"><v-icon size="8" class="mr-2" color="grey">mdi-circle</v-icon>Neutral </div>
        </div>
        <v-btn v-if="!enableSentiment" text class="mt-4" elevation="0" small color="deep-purple accent-3" @click="openDialog">
            <v-icon  color="deep-purple accent-3" left>mdi-diamond</v-icon>
            Unlock Sentiment Analysis
        </v-btn>
    </v-card>
</template>

<script>
import useSWRV from 'swrv';
import { useRoute } from 'vue-router/composables';
import fetcher from '@/api/fetcher';
import useSwrvState from '@/utils/useSwrvState'
import { mapGetters, mapMutations } from 'vuex';

export default {
    computed: {
        ...mapGetters({
            features: 'auth/features',
        }),
        enableSentiment(){
            return !!this.features && this.features.audio_analytics
        },
    },
    methods: {
        ...mapMutations(['openDialog']),
    },
    setup(){
        const route = useRoute();
        const { data, error, isValidating, mutate } = useSWRV(
            () =>`/api/analytics/${route.params.surveyId}/questions/${route.params.questionId}/sentiments`,
            fetcher
        )
        const { state, STATES } = useSwrvState(data, error, isValidating)

        return {
            state, STATES,
            results: data,
            error,
            isValidating,
            revalidateResults: mutate,
        }
    }
    
}
</script>

<style lang="scss" scoped>
.progress{
    display: flex;
    height: 8px;
    border-radius: 4px;
    overflow: hidden;
    &-item{
        height: 8px;
        transition: width 300ms;
    }
    .positive{
        background-color: var(--v-success-lighten1);
    }
    .negative{
        background-color: var(--v-error-lighten1);
    }
    .neutral{
        background-color: var(--v-grey-lighten1);
    }
}
</style>