<template>
    <v-chip v-if="sentiment" :small="small"  :color="sentiment.color">{{sentiment.label}}</v-chip>
</template>

<script>
const SENTIMENT_REPRESENTATION = {
    'neutral': {
        label: 'Neutral',
        color: 'default',
        class: null
    },
    'positive': {
        label: 'Positive',
        color: 'success lighten-3 black--text',
        class: 'text--black'
    },
    'negative': {
        label: 'Negative',
        color: 'error lighten-3 black--text'

    }
}
export default {
    name: "SentimentChip",
    props: {
        sentimentValue: {
            required: true,
        },
        small: {
            default: false
        }
    },
    computed: {
        sentiment(){
            return SENTIMENT_REPRESENTATION[this.sentimentValue]
        }
    }
}
</script>
