<template>
    <v-expansion-panel v-if="['processing', 're-processing'].includes(answer.status)" class="px-6 py-4">
        <processing-list-item :createdDate="createdDate" :name="name" :email="email"></processing-list-item>
    </v-expansion-panel>
    <v-expansion-panel v-else>
        <v-expansion-panel-header>
            <v-row no-gutters>
                <v-col>
                    <v-col>
                        <span class="text-caption">Audio</span>
                    </v-col>
                    <v-col :class="answer.file_url ? 'text-body-2' : 'text-body-1 mt-4'">
                        <div v-if="answer.file_url">
                            <audio
                                ref="currentAudio" controls
                            >
                                <source :src="answer.file_url" type="audio/mpeg"/>
                            </audio>
                        </div>
                        <span v-else>N/A</span>
                    </v-col>
                </v-col>
                <v-col style="min-width: 140px;" md="1">
                    <v-col>
                        <span class="text-caption">Created</span>
                    </v-col>
                    <v-col class="text-body-1 mt-4">
                        <span v-if="createdDate">{{createdDate}}</span>
                        <span v-else>N/A</span>
                    </v-col>
                </v-col>
                <v-col v-if="showLeadInfo">
                    <v-col>
                        <span class="text-caption">Lead</span>
                    </v-col>
                    <v-col class="text-body-1 mt-4">
                        <span>{{leadInfo}}</span>
                    </v-col>
                </v-col>
                <v-spacer></v-spacer>
                <v-col md="2" class="min-width">
                    <v-col>
                        <span class="text-caption">Sentiment</span>
                    </v-col>
                    <v-col class="mt-4 text-body-1">
                        <div v-if="enabledAudioAnalytics && answer.sentiment" class="mt-n2">
                            <sentiment-chip  :sentimentValue="answer.sentiment"></sentiment-chip>
                        </div>
                        <span v-else>{{"N/A"}}</span>
                    </v-col>
                </v-col>
            </v-row>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
            <v-card :elevation="0" color="grey lighten-4">
                <v-row no-gutters>
                    <v-col md="12" lg="6">
                        <v-col>
                            <span class="text-caption">Transcript</span>
                        </v-col>
                        <v-col>
                            <div style="white-space: pre-line;" v-if="enabledTranscription && answer.transcript">
                                {{answer.transcript}}
                            </div>
                            <span v-else>{{"N/A"}}</span>
                        </v-col>
                    </v-col>
                    <v-col md="12" lg="6">
                        <v-col>
                            <span class="text-caption">Keywords Sentiment Scores</span>
                        </v-col>
                        <v-col>
                            <keywords-sentiment-scores-list v-if="enabledAudioAnalytics" :answer="answer" />
                            <span v-else>{{"N/A"}}</span>
                        </v-col>
                    </v-col>
                </v-row>
            </v-card>
        </v-expansion-panel-content>
    </v-expansion-panel>
</template>
<script>
import { mapGetters } from "vuex";
import KeywordsSentimentScoresList from '../keywords/KeywordsSentimentScoresList.vue';
import SentimentChip from '../sentiment/SentimentChip.vue';
import ProcessingListItem from './ProcessingListItem.vue';
export default {
  components: { SentimentChip, KeywordsSentimentScoresList, ProcessingListItem },
    name: "ListItem",
    props: {
        answer: {
            required: true,
        },
        showLeadInfo: {
            default: true,
        },
        email: {
            required: false
        },
        name: {
            required: false
        }
    },
    computed: {
        ...mapGetters({ 
            isPaidAccount: "auth/isPaidAccount",
            features: 'auth/features'
        }),
        enabledTranscription(){
            return this.features && this.features.transcription
        },
        enabledAudioAnalytics(){
            return this.features && this.features.audio_analytics
        },
        createdDate(){
            return this.$date(this.answer.created_at).format('YYYY-MM-DD');
        },
        leadInfo(){
            return this.email || this.name || 'N/A';
        },
    },
    mounted() {
        if (this.$refs.currentAudio) {
            this.$refs.currentAudio.onplay = () => {
                document.querySelectorAll('audio').forEach((element) => {
                    if (this.$refs.currentAudio !== element) {
                        element.pause();
                    }
                });
            };
        }
    }
}
</script>
