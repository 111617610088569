<template>
    <v-card class="pa-4" outlined v-if="!enableSentiment">
        <div class="d-flex">
            <span class="text-subtitle-2 mr-2">Top Themes</span>
        </div>
        <div class="d-flex align-center mt-6">
            <div class="title text-body-1 mr-2"><v-icon size="8" class="mr-2" color="success lighten-1">mdi-circle</v-icon>Positive</div>
                <v-chip-group
                    column
                    multiple    
                    class="blur"  
                >
                    <v-chip
                        v-for="index in 3"
                        :key="index"
                        filter
                        :ripple="false"
                        color="green lighten-5"
                        label
                    >
                        {{ 'keyword '+index }}
                    </v-chip>
                </v-chip-group>
        </div>
        <div class="d-flex align-center mt-6">
                <div class="title text-body-1 mr-2"><v-icon size="8" class="mr-2" color="error lighten-1">mdi-circle</v-icon>Negative</div>
                <v-chip-group
                    column
                    multiple 
                    class="blur"  
                >
                    <v-chip
                        v-for="index in 5"
                        :key="index"
                        filter
                        :ripple="false"
                        color="error lighten-4"
                        class="secondary--text"
                        label
                    >
                        {{ 'keyword '+ index }}
                    </v-chip>
                </v-chip-group>
        </div>
        <div class="d-flex align-center mt-6">
                <div class="title text-body-1 mr-2"><v-icon size="8" class="mr-2" color="grey">mdi-circle</v-icon>Neutral</div>
                <v-chip-group
                    column
                    multiple   
                    class="blur"  
                >
                    <v-chip
                        v-for="index in 2"
                        :key="index"
                        filter
                        :ripple="false"
                        label
                    >
                        {{ 'keyword '+ index }}
                    </v-chip>
                </v-chip-group>
        </div>
        <v-btn v-if="!enableSentiment" text class="mt-4" elevation="0" small color="deep-purple accent-3" @click="openDialog">
            <v-icon color="deep-purple accent-3" left>mdi-diamond</v-icon>
            Unlock Theme Analysis
        </v-btn>
    </v-card>
    <v-card outlined v-else-if="![STATES.PENDING].includes(state) && keywords" class="pa-4">
        <div class="d-flex">
                <span class="text-subtitle-2 mr-2">Top Themes</span>
                <div class="my-n1" v-if="selectedKeywords.length">
                    <span class="text--grey-lighten text-body-2 mr-1">{{selectedKeywords.length}} applied theme(s)</span>
                    <v-btn small text color="primary" @click="selectedKeywords=[]">Reset</v-btn>
                </div>
        </div>
        <div v-if="positiveKeywords && positiveKeywords.length" class="d-flex align-center mt-6">
            <div class="title text-body-1 mr-2"><v-icon size="8" class="mr-2" color="success lighten-1">mdi-circle</v-icon>Positive</div>
                <v-chip-group
                    column
                    multiple
                    v-model="selectedKeywords"       
                >
                    <v-chip
                        v-for="keyword in positiveKeywords"
                        :key="keyword.text"
                        filter
                        :ripple="false"
                        :value="keyword.text"
                        :color="keywordColor(keyword)"
                        label
                    >
                        <v-icon color="success" left v-if="!selectedKeywords.includes(keyword.text)">
                            mdi-plus-circle-outline
                        </v-icon>
                        {{formatKeywordText(keyword)}}
                    </v-chip>
                </v-chip-group>
        </div>
        <div v-if="negativeKeywords && negativeKeywords.length" class="d-flex align-center mt-6">
                <div class="title text-body-1 mr-2"><v-icon size="8" class="mr-2" color="error lighten-1">mdi-circle</v-icon>Negative</div>
                <v-chip-group
                    column
                    multiple
                    v-model="selectedKeywords"       
                >
                    <v-chip
                        v-for="keyword in negativeKeywords"
                        :key="keyword.text"
                        filter
                        :ripple="false"
                        :value="keyword.text"
                        :color="keywordColor(keyword)"
                        label
                    >
                        <v-icon color="error" left v-if="!selectedKeywords.includes(keyword.text)">
                            mdi-plus-circle-outline
                        </v-icon>
                        {{formatKeywordText(keyword)}}
                    </v-chip>
                </v-chip-group>
        </div>
        <div v-if="neutralKeywords && neutralKeywords.length" class="d-flex align-center mt-6">
                <div class="title text-body-1 mr-2"><v-icon size="8" class="mr-2" color="grey">mdi-circle</v-icon>Neutral</div>
                <v-chip-group
                    column
                    multiple
                    v-model="selectedKeywords"       
                >
                    <v-chip
                        v-for="keyword in neutralKeywords"
                        :key="keyword.text"
                        filter
                        :ripple="false"
                        :value="keyword.text"
                        :color="keywordColor(keyword)"
                        label
                    >
                        <v-icon color="grey darken-2" left v-if="!selectedKeywords.includes(keyword.text)">
                            mdi-plus-circle-outline
                        </v-icon>
                        {{formatKeywordText(keyword)}}
                    </v-chip>
                </v-chip-group>
        </div>
    </v-card>
</template>
<script>
import useSWRV from 'swrv';
import { useRoute } from 'vue-router/composables';
import fetcher from '@/api/fetcher';
import useSwrvState from '@/utils/useSwrvState'
import { mapGetters, mapMutations } from 'vuex';



export default {
    name: "TopKeywordsFilter",
    computed: {
        ...mapGetters({
            features: 'auth/features',
        }),
        enableSentiment(){
            return !!this.features && this.features.audio_analytics
        },
        selectedKeywords:{
            get(){
                return this.$route && this.$route.query && this.$route.query.keywords ? this.$route.query.keywords.split(',') : []
            },
            set(value){
                this.$router.push({query: {...this.$route.query, keywords: value.join(',')}})
            }
        },
        positiveKeywords(){
            if(!this.keywords) return []
            return this.keywords.positive
        },
        negativeKeywords(){
            if(!this.keywords) return []
            return this.keywords.negative
        },
        neutralKeywords(){
            if(!this.keywords) return []
            return this.keywords.neutral
        },
    },
    
    methods: {
        formatKeywordText(keyword){
            if(keyword.count === 1){
                return keyword.text
            }
            return `${keyword.text} (${keyword.count})`
        },

        avgSentiment(keyword){
            return keyword.sentimentSum/keyword.count
        },
        avgRelevance(keyword){
            return keyword.relevanceSum/ keyword.count
        },

        keywordColor(keyword){
            if(keyword){
                const avgSentiment = this.avgSentiment(keyword)
                if(avgSentiment< -0.25){
                    return 'error lighten-5 black--text'
                }

                if(avgSentiment> 0.25){
                    return 'success lighten-5 black--text'
                }
            }
            
            return 'default'
        },
        ...mapMutations(['openDialog']),
    },
    setup(){
        const route = useRoute();
        const { data, error, isValidating, mutate } = useSWRV(
            () =>`/api/analytics/${route.params.surveyId}/questions/${route.params.questionId}/top-keywords`,
            fetcher
        )
        const { state, STATES } = useSwrvState(data, error, isValidating)

        return {
            state, STATES,
            keywords: data,
            error,
            isValidating,
            revalidateKeywords: mutate,
        }
    }
}
</script>
<style lang="scss" scoped>
    .title{
        color: var(--v-grey-darken3);
        min-width: 100px
    }
    .blur{
        filter: blur(4px);
        pointer-events: none;
    }
</style>