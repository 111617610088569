var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('div',{staticClass:"d-flex mb-2"},[_c('v-spacer'),_c('div',{staticClass:"d-flex flex-grow-1 align-center justify-end mr-2"},[(_vm.containAudio)?_c('upgrade-wrapper',{directives:[{name:"show",rawName:"v-show",value:(hover),expression:"hover"}],attrs:{"blocked":!_vm.enableAudioExport}},[_c('v-btn',{attrs:{"color":!_vm.enableAudioExport? 'deep-purple accent-3': 'default',"icon":""}},[_c('v-icon',{on:{"click":_vm.downloadAudio}},[_vm._v("mdi-download")])],1)],1):_vm._e(),(!_vm.disableLike && (hover || _vm.liked))?_c('div',{staticStyle:{"max-width":"100px"}},[_c('v-btn',{attrs:{"icon":""},on:{"click":_vm.handleLike}},[(_vm.liked)?_c('v-icon',{attrs:{"color":"red"}},[_vm._v("mdi-heart")]):_c('v-icon',[_vm._v("mdi-heart-outline")])],1)],1):_vm._e()],1),_c('v-card',{staticClass:"d-flex pa-4 rounded-lg flex-column",attrs:{"outlined":"","max-width":"80%"}},[_c('div',[_c('div',{staticClass:"d-flex align-center"},[(_vm.containAudio)?_c('results-audio-item-player',{attrs:{"answer":_vm.answer,"source":_vm.answer.file_url}}):_vm._e(),_c('div',{staticClass:"mr-2"},[(!_vm.containAudio)?_c('div',[_c('v-chip',{staticClass:"pa-1",attrs:{"label":"","small":""}},[_vm._v("Text Only")])],1):_vm._e()])],1)]),_c('div',{staticClass:"d-flex mt-2"},[(_vm.answer.sentiment)?_c('div',[_c('div',{staticClass:"d-flex justify-center",staticStyle:{"white-space":"nowrap"}},[_c('v-tooltip',{attrs:{"color":!_vm.enableSentiment?'deep-purple accent-3':'secondary',"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(!_vm.enableSentiment)?_c('upgrade-wrapper',_vm._g(_vm._b({attrs:{"blocked":!_vm.enableSentiment}},'upgrade-wrapper',attrs,false),on),[_c('v-btn',{attrs:{"icon":"","color":"deep-purple accent-3"}},[_c('v-icon',[_vm._v("$sentiment")])],1)],1):(_vm.answer.sentiment === 'negative')?_c('v-icon',_vm._g(_vm._b({attrs:{"color":"red"}},'v-icon',attrs,false),on),[_vm._v(" mdi-emoticon-frown-outline")]):(_vm.answer.sentiment === 'positive')?_c('v-icon',_vm._g(_vm._b({attrs:{"color":"green"}},'v-icon',attrs,false),on),[_vm._v(" mdi-emoticon-outline")]):(_vm.answer.sentiment === 'neutral')?_c('v-icon',_vm._g(_vm._b({},'v-icon',attrs,false),on),[_vm._v(" mdi-emoticon-neutral-outline")]):_c('span',{staticClass:"text-body-2 grey--text text--darken-1"},[_vm._v("--")])]}}],null,true)},[(!_vm.enableSentiment)?_c('span',[_c('v-icon',{attrs:{"small":"","color":"white","left":""}},[_vm._v("mdi-diamond")]),_vm._v("Upgrade")],1):(_vm.answer.sentiment === 'neutral')?_c('span',[_vm._v("neutral")]):(_vm.answer.sentiment && _vm.answer.sentiment_score)?_c('span',[_vm._v(" "+_vm._s(Math.round(Math.abs(_vm.answer.sentiment_score) * 100) + '% ' + _vm.answer.sentiment)+" ")]):_c('span',[_vm._v("Undefined Sentiment")])])],1)]):_vm._e(),(_vm.answer.sentiment)?_c('v-divider',{staticClass:"mx-2",attrs:{"width":"2","vertical":""}}):_vm._e(),_c('div',{class:_vm.containAudio?'text-caption': 'text-body-2'},[(!_vm.enableTranscript)?_c('span',[_vm._v(" "+_vm._s(_vm.formattedTranscript)+" "),_c('a',{staticClass:"text-decoration-underline deep-purple--text text--accent-3",on:{"click":_vm.openDialog}},[_c('v-icon',{staticClass:"mr-1",attrs:{"color":"deep-purple accent-3","x-small":""}},[_vm._v("mdi-diamond")]),_vm._v(_vm._s(_vm.formattedTranscript? 'read more': 'transcript'))],1)]):(['processing', 're-processing'].includes(_vm.answer.status))?_c('v-tooltip',{attrs:{"bottom":"","max-width":260},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-chip',_vm._g(_vm._b({attrs:{"label":"","small":"","color":"primary lighten-2"}},'v-chip',attrs,false),on),[_vm._v("Processing")])]}}],null,true)},[_c('span',[_vm._v("Some responses require a little more time to process. Refresh this page or check back soon 🙌")])]):(!_vm.answer.transcript)?_c('span'):[_c('span',[_vm._v(_vm._s(_vm.transcript))]),(_vm.hasTranslation)?_c('a',{staticClass:"text-caption grey--text pt-1 d-block",on:{"click":function($event){_vm.showOriginal=!_vm.showOriginal}}},[_vm._v(_vm._s(_vm.showOriginal? 'View Translation' : 'View Original'))]):_vm._e()]],2)],1)]),_c('v-avatar',{staticClass:"mt-1 ml-2",attrs:{"size":"32","color":"grey lighten-3"}},[(!_vm.avatarUrl)?_c('v-icon',[_vm._v("mdi-account")]):_c('img',{attrs:{"src":_vm.avatarUrl}})],1)],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }