<template>
    <div class="d-flex flex-grow-1">
        <v-progress-linear 
            class="progress-negative"
            color="error lighten-3" 
            height="12" 
            :value="negativeValue" 
            background-color="grey lighten-2" 
            reverse>
        </v-progress-linear>
        <div class="progress-divider"></div>
        <v-progress-linear
            class="progress-positive" 
            color="success lighten-3" 
            height="12" 
            :value="positiveValue" 
            background-color="grey lighten-2">
        </v-progress-linear>
    </div>
</template>
<script>

export default {
    props: {
        value: {
            required: true
        }
    },
    computed: {
        negativeValue(){
            if(this.value < 0){
               return  Math.abs(this.value * 100)
            }
            return 0
        },
        positiveValue(){
            if(this.value > 0){
               return  this.value * 100 
            }
            return 0
        }
    }
}
</script>

<style lang="scss" scoped>
.progress{
    &-negative{
        border-top-left-radius: 12px;
        border-bottom-left-radius: 12px;
    }
    &-positive{
        border-top-right-radius: 12px;
        border-bottom-right-radius: 12px;
    }
    &-divider{
        width: 4px;
        background-color: var(--v-grey-base)
    }
}
</style>
