var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-slide-x-transition',[_c('v-card',{staticClass:"pa-4 mb-6",attrs:{"outlined":"","to":{
            path: ("/dashboard/voiceform/" + (_vm.$route.params.surveyId) + "/results/respondents/" + (_vm.result.id)),
        },"color":_vm.active? 'primary': '',"dark":_vm.active,"id":("responder-" + (_vm.result.id))}},[_c('v-hover',{staticClass:"d-flex flex-grow-1",scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var hover = ref.hover;
return [_c('div',[(_vm.result.survey_count)?_c('div',{staticClass:"d-flex align-center question-type-badge"},[_c('div',{staticClass:"text-body-2"},[_vm._v(_vm._s(("#" + (_vm.result.survey_count))))])]):_vm._e(),_c('div',{staticClass:"text-caption flex-grow-1 px-4"},[_c('div',{class:_vm.active?'primary--text text--lighten-5':'grey--text text--darken-1'},[_vm._v(" "+_vm._s(_vm.$date(_vm.result.created_at).format('MMM D, YYYY')))]),(!_vm.result.expires_at && _vm.responderName)?_c('div',{staticClass:"mt-2 elipsis-text"},[_vm._v(_vm._s(_vm.responderName))]):_vm._e(),(!_vm.result.expires_at && _vm.responderEmail)?_c('div',{staticClass:"mt-2 elipsis-text"},[_vm._v(_vm._s(_vm.responderEmail))]):_vm._e(),(_vm.result.expires_at)?_c('v-chip',{class:['mt-2', {'deep-orange--text text--darken-4': _vm.active}],attrs:{"small":"","elevation":"0","color":_vm.active?'deep-orange lighten-4' :'deep-orange lighten-1',"dark":"","label":""}},[_c('v-icon',{attrs:{"small":"","left":""}},[_vm._v(" mdi-lock-outline ")]),_vm._v(" expires "+_vm._s(_vm.$date(_vm.result.expires_at).fromNow())+" ")],1):_vm._e()],1),_c('v-menu',{attrs:{"offset-x":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mx-n4",attrs:{"plain":"","icon":""},on:{"click":function($event){$event.preventDefault();}}},'v-btn',attrs,false),on),[(hover || (attrs &&  attrs['aria-expanded']==='true'))?_c('v-icon',[_vm._v("mdi-dots-vertical")]):_vm._e()],1)]}}],null,true)},[_c('v-list',[_c('v-list-item',{on:{"click":function($event){return _vm.$emit('copyLink', _vm.result)}}},[_c('v-list-item-title',[_vm._v("Copy link")])],1),_c('v-list-item',{on:{"click":function($event){return _vm.$emit('delete', _vm.result)}}},[_c('v-list-item-title',[_vm._v("Delete result")])],1)],1)],1)],1)]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }