<template>
    <div class="d-flex flex-column align-center">
        <img src="https://cdn.voiceform.com/media%2Flock-illustration.svg" height="264" style="height: 264px;" />
        <div class="mt-6 text-h6"><v-icon class="mt-n2" left>mdi-lock</v-icon>This response is locked</div>
        <div class="mt-4 text-body-2 text-center grey--text text--darken-1" style="max-width: 500px">The response was locked because it was submitted after your team account has reached its monthly response limit.</div>
        <div class="mt-6 text-body-1 text-center" style="max-width: 500px">To unlock this response, your should be on <v-chip plain class="mt-2" color="primary lighten-5" text-color="primary darken-5"><v-icon left>mdi-arrow-up-bold-circle-outline</v-icon>{{ suggestedPlan }} plan</v-chip></div>
        <div class="mt-6 text-body-2 text-center grey--text text--darken-1" style="max-width: 500px">If you choose to remain on your current plan, this response will be removed <b>{{ $date(expiresAt).fromNow()}}</b>.</div>
        <v-btn large @click="handleUpgradeClick" class="mt-4" elevation="0" v-if="showUpgradeButton" color="primary">
            <v-icon left>mdi-lock-open-variant-outline</v-icon>
            {{isCustomPlan?'Contact Voiceform':'Upgrade Now'}}
        </v-btn>
        <div class="d-flex flex-column align-center" v-else>
            <div class="mt-4 text-body-1">Contact your team owner <a v-if="teamOwnerEmail" :href="mailLink">{{teamOwnerEmail}}</a> for an upgrade 🙌</div>
            <v-btn  v-if="teamOwnerEmail" :href="mailLink" elevation="0" class="mt-4" color="primary" >
                <v-icon left>mdi-lock-open-variant-outline</v-icon>
                Contact Team Owner
            </v-btn>
        </div>
        <v-dialog
            v-model="contactUsDialog"
            persistent
            max-width="600px"
        >
            <contact-us-form :message="message" formHeader="Contact to upgrade your custom plan" @close="contactUsDialog=false" @submit="handleSubmit"></contact-us-form>
        </v-dialog>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
import ContactUsForm from '../../../upgrade/ContactUsForm.vue'

export default {
    name: 'LockedResponse',
    components: { ContactUsForm },
    data(){
        return {
            contactUsDialog: false
        }
    },
    props:{
        expiresAt:{
            required: true
        },
        submissionCount: {
            required: true
        }
    },
    computed: {
        ...mapGetters({ 
            activeMembership: 'teams/activeMembership',
            subscription: 'billing/subscription',
            pricePlan: 'billing/pricePlan',
        }),
        showUpgradeButton(){
            if(this.activeMembership && this.activeMembership.role !== 'owner'){
                return false
            }
            return true
        },
        teamOwnerEmail(){
            if(this.activeMembership && this.activeMembership.team && this.activeMembership.team.owner_email){
                return this.activeMembership.team.owner_email
            }
            return ''
        },
        mailLink(){
            return `mailto:${this.teamOwnerEmail}?subject=Upgrade%20Voiceform%20Account&body=Hi%2CI%E2%80%99ve%20received%20an%20email%20from%20Voicefrom%20indicating%20we%E2%80%99ve%20reached%20our%20monthly%20response%20limit.%20In%20order%20to%20view%20voiceform%20submissions%20that%20are%20above%20our%20current%20limit%2C%20you%E2%80%99ll%20need%20to%20upgrade%20the%20account.Thanks!`
        },
        hidePricing(){
            return process.env.VUE_APP_HIDE_PRICING === 'true'
        },
        isCustomPlan(){
            if(this.hidePricing){
                return true
            }
            if(this.submissionCount>100 || (this.pricePlan && !this.subscription)){
                return true
            }
            return false
        },
        message(){
            return `Dear Voiceform Team,\n\nI am reaching out to you today to inform you that our account has reached its monthly submission limit. As a result, we would like to upgrade our account and unlock some of the responses.\nCould you please guide us on the process to upgrade our account and provide us with the necessary details to proceed with the upgrade? We are looking forward to hearing back from you soon.\n\nThank you for your assistance and support.`
        },
        suggestedPlan(){
            if(this.submissionCount<=25){
                return 'Essentials or higher'
            }

            if(this.submissionCount<=100){
                return 'Pro or higher'
            }
            return 'Enterprise'
        }
    },
    methods: {
        handleUpgradeClick(){
            if(this.isCustomPlan){
                window.heap.track('open-contact-us-dialog', {
                    'submission_count': this.submissionCount,
                    'expiresAt': this.expiresAt
                });
                return this.contactUsDialog = true

            }
            window.heap.track('upgrade-from-lock', {
                'submission_count': this.submissionCount,
                'expiresAt': this.expiresAt
            });
            return this.$router.push({name: 'dashboard.upgrade'})
        },
        handleSubmit(){
            window.heap.track('submitted-contact-us-dialog', {
                'submission_count': this.submissionCount,
                'expiresAt': this.expiresAt
            });
            this.contactUsDialog = false
        }
    }
}
</script>