var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('div',{staticClass:"d-flex mb-2"},[_c('v-spacer'),_c('div',{staticClass:"d-flex flex-grow-1 align-center justify-end mr-2"},[(!_vm.disableLike && (hover || _vm.liked))?_c('div',{staticStyle:{"max-width":"100px"}},[_c('v-btn',{attrs:{"icon":""},on:{"click":_vm.handleLike}},[(_vm.liked)?_c('v-icon',{attrs:{"color":"red"}},[_vm._v("mdi-heart")]):_c('v-icon',[_vm._v("mdi-heart-outline")])],1)],1):_vm._e()]),_c('v-card',{staticClass:"d-flex rounded-lg align-top pa-4",attrs:{"outlined":"","max-width":"80%"}},[(_vm.answer)?[_c('div',[(['processing', 're-processing'].includes(_vm.answer.status))?_c('v-tooltip',{attrs:{"bottom":"","max-width":260},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-chip',_vm._g(_vm._b({attrs:{"label":"","small":"","color":"primary lighten-2"}},'v-chip',attrs,false),on),[_vm._v("Processing")])]}}],null,true)},[_c('span',[_vm._v("Some responses require a little more time to process. Refresh this page or check back soon 🙌")])]):(_vm.matrixType)?_c('matrix-transcript',{attrs:{"answer":_vm.answer}}):(_vm.textBasedAnswer)?_c('v-sheet',{staticClass:"pa-1",attrs:{"color":"primary lighten-5","rounded":""}},[(!_vm.longTranscript || _vm.expandTranscript)?_c('span',{staticClass:"text-body-2",staticStyle:{"color":"var(--v-primary-darken1)"}},[_vm._v(" "+_vm._s(_vm.transcript)+" "),(_vm.expandTranscript)?_c('a',{staticClass:"text-decoration-underline",on:{"click":function($event){$event.preventDefault();(_vm.expandTranscript = !_vm.expandTranscript)}}},[_vm._v("collapse")]):_vm._e()]):_c('span',{staticClass:"text-body-2",staticStyle:{"color":"var(--v-primary-darken1)"}},[_vm._v(" "+_vm._s(_vm.formattedTranscript)+" "),_c('a',{staticClass:"text-decoration-underline",on:{"click":function($event){$event.preventDefault();(_vm.expandTranscript = !_vm.expandTranscript)}}},[_vm._v("read more")])])]):(_vm.contentOverflow)?_c('v-tooltip',{staticClass:"mt-1",attrs:{"bottom":"","max-width":260},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-chip',_vm._g(_vm._b({attrs:{"color":"primary lighten-5"}},'v-chip',attrs,false),on),[_c('span',{staticClass:"answer-chip",staticStyle:{"color":"var(--v-primary-darken1)"}},[_vm._v(" "+_vm._s(_vm.transcript)+" ")])])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.transcript))])]):_c('div',{staticClass:"text-body-2"},[_vm._v(" "+_vm._s(_vm.transcript)+" ")])],1)]:_vm._e()],2),_c('v-avatar',{staticClass:"mt-1 ml-2",attrs:{"size":"32","color":"grey lighten-3"}},[(!_vm.avatarUrl)?_c('v-icon',[_vm._v("mdi-account")]):_c('img',{attrs:{"src":_vm.avatarUrl}})],1)],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }