<template>
    <v-card v-if="entries && entries.length" class="pa-4" outlined>
        <div class="d-flex flex-column">
            <div class="text-subtitle-2 mb-4">Question Summary</div>
            <template v-for="frequency in entries">
                <percentage-bar  :key="frequency[0]" :label="frequency[0]" :counter-value="frequency[1].counter" :percentage="frequency[1].percentage"/>
            </template>
        </div>
    </v-card>
</template>

<script>
import useSWRV from 'swrv';
import { useRoute } from 'vue-router/composables';
import fetcher from '@/api/fetcher';
import useSwrvState from '@/utils/useSwrvState'
import PercentageBar from '../../../../components/chart/PercentageBar.vue'
export default {
    components: { PercentageBar },
    computed:{
        entries(){
            if(this.frequencies){
                return Object.entries(this.frequencies)
            }
            return []
        }
    },
    setup(){
        const route = useRoute();
        const { data, error, isValidating, mutate } = useSWRV(
            () =>`/api/analytics/${route.params.surveyId}/questions/${route.params.questionId}/frequencies`,
            fetcher
        )
        const { state, STATES } = useSwrvState(data, error, isValidating)

        return {
            state, STATES,
            frequencies: data,
            error,
            isValidating,
            revalidateResults: mutate,
        }
    }
}
</script>