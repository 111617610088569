<template>
    <div class="py-4 d-flex flex-column flex-1">
        <v-row align="center">
            <v-col sm="5">
                <div>Primary Color</div>
            </v-col>
            <v-col sm="7">
                <color-picker v-model="design.primaryColor"></color-picker>
            </v-col>
        </v-row>
        <v-row align="center">
            <v-col sm="5">
                <div>Icon</div>
            </v-col>
            <v-col sm="7">
                <v-btn v-if="!design.iconUrl" @click="handleAddImage" :disabled="uploadingImage" :loading="uploadingImage" elevation="0" small >Add</v-btn>
                <div v-else class="d-flex align-center">
                    <img class="mr-2" :src="design.iconUrl" width="40" height="40" style="width: 40px; height: 40px; object-fit: contain; border-radius: 4px; overflow: hidden;" />
                    <v-btn @click="design.iconUrl=''" icon>
                        <v-icon>mdi-delete-outline</v-icon>
                    </v-btn>
                </div>
                <input
                    ref="uploader"
                    hidden
                    type="file"
                    accept="image/png, image/jpeg, image/bmp"
                    @change="uploadAudioFile($event)"
                />
            </v-col>
        </v-row>
        <v-row>
            <v-col sm="5">
                <div>Caption Text</div>
            </v-col>
            <v-col sm="7">
                <v-text-field outlined dense hide-details="auto" v-model="design.captionText" counter="60"></v-text-field>
            </v-col>
        </v-row>
        <v-row align="center">
            <v-col sm="5">
                <div>Reaction</div>
            </v-col>
            <v-col sm="7">
                <div class="d-flex align-center">
                    <v-switch class="mt-0 pt-0 mr-2" hide-details="auto" v-model="design.reaction"></v-switch>
                    <emoji-selector v-model="design.reactionIcon"></emoji-selector>
                </div>
            </v-col>
            
        </v-row>
        <v-row align="center">
            <v-col sm="5">
                <div>Watermark</div>
            </v-col>
            <v-col sm="7">
                <div @click="handleSwitchClick">
                    <v-switch :disabled="!enableHideBranding" class="mt-0 pt-0" hide-details="auto" v-model="design.watermark"></v-switch>
                </div>
            </v-col>
        </v-row>
    </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'
import { uploadMediaFile } from '../../api/survey'
import ColorPicker from '../../views/dashboard/voiceform/create/design/ColorPicker.vue'
import EmojiSelector from './EmojiSelector.vue'
export default {
    components: {ColorPicker, EmojiSelector},
    data(){
        return{
            uploadingImage: false
        }
    },
    computed: {
        ...mapGetters({
            user: 'auth/user',
            survey: 'survey/survey',
            features: 'auth/features'
        }),
        design(){
            return this.$store.state.visualization.design
        },
        enableHideBranding(){
            return !!this.features && this.features.remove_branding
        },
    },
    methods: {
        ...mapMutations(['showSnackBar']),
        handleAddImage(){
            this.$refs.uploader.click()
        },

        async uploadAudioFile(e){
            try {
                this.uploadingImage = true
                let { data } = await uploadMediaFile(e.target.files[0], this.survey.hash);
                this.uploadingImage = false
                this.design.iconUrl = data.url;
            } catch (error) {
                this.uploadingImage = false
                this.showSnackBar({
                    text: "Cannot upload file. Please try again later.", 
                    color: 'error', 
                    timeout: 2000
                })
            }
        },
        handleSwitchClick(){
            if(!this.enableHideBranding){
                return this.showSnackBar({
                    text: "Not available on your current plan.", 
                    color: 'info', 
                    timeout: 2000
                })
            }
        }
    }
}
</script>