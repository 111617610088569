<template>
    <v-dialog width="90%" max-width="655" v-model="$store.state.site.dialog" v-if="includeDialogs">
        <v-card class="card">
            <div class="left-side">
                <v-img contain :src="illustration" alt="Rocket illustration" max-height="100%" :max-width="dialogIllustration?'100%':'90%'"></v-img>
            </div>
            <div class="pa-5 d-flex flex-column">
                <div class="text-h4 mb-7">Upgrade to Access this Feature! 🚀</div>
                <div class="text-body-1 mb-3">{{ bodyText }}</div>
                <v-spacer></v-spacer>
                <v-btn rounded elevation="0" large @click="handleUpgradeClick" color="primary" >
                    <span class="text-subtitle-1">Upgrade Plan 💎</span>
                </v-btn>
            </div>
        </v-card>
    </v-dialog>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'
export default {
    computed: {
        ...mapGetters(['dialogBodyText', 'dialogIllustration']),
        bodyText(){
            return this.dialogBodyText || 'Upgrade your plan today to extract the full value from our platform, including this feature and many more!'
        },
        includeDialogs(){
            return !['survey-demo', 'single-question-widget'].includes(this.$route.name)
        },
        illustration(){
            return this.dialogIllustration || 'https://cdn.voiceform.com/media/rocket-illustration.svg'
        }
    },
    methods: {
        ...mapMutations(['closeDialog']),
        handleUpgradeClick(){
            this.closeDialog()
            this.$router.push({
                name: 'dashboard.upgrade',
            })
        }
    }

}
</script>

<style lang="scss" scoped>

    .card{
        display: flex;
        overflow: hidden;
    }
    .left-side{
        border-top-right-radius: 0px !important;
        border-bottom-right-radius: 0px !important;
        min-height: 100%;
        min-width: 257px;
        background: linear-gradient(180deg, #2341E0 0%, #0066FF 100%);
        display: flex;
        align-items: center;
        justify-content: center;
    }

</style>