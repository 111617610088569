<template>
        <response-skeleton-loader v-if="[STATES.PENDING].includes(state)"></response-skeleton-loader>
        <v-container v-else-if="result && result.expires_at">
            <locked-response :expiresAt="result.expires_at" :submissionCount="result.submission_count_current_period"></locked-response>
        </v-container>
        <div v-else-if="result">
            <v-row no-gutters class="d-flex flex-grow-1 chat-container">
                <v-col class="d-flex" style="height: 100%" lg="4">
                    <v-card style="overflow-y: auto;" class="pa-6 flex-grow-1 ma-4" outlined>
                        <div class="d-flex mb-8 flex-wrap">
                            <h6 class="text-h6 mb-6">
                                Responder 
                                <span v-if="result.survey_count" class="result-id primary--text text--darken-1"># {{result.survey_count}}</span>
                            </h6>
                            <v-spacer></v-spacer>
                            <v-menu bottom nudge-bottom="35px">
                                <template v-slot:activator="{ on, attrs }">
                                        <v-btn
                                            color="grey lighten-3"
                                            elevation="0"
                                            v-bind="attrs"
                                            v-on="on"
                                            small
                                        >
                                            Export
                                            <v-icon right>mdi-menu-down</v-icon>
                                        </v-btn>
                                </template>
                                <v-list>
                                    <upgrade-wrapper v-if="showDownload" :blocked="!enableAudioExport">
                                        <v-list-item  @click.prevent="downloadAudio">
                                            <v-icon :color="enableAudioExport?'grey darken-2':'deep-purple accent-3'" left small>{{enableAudioExport?'mdi-download': 'mdi-diamond'}}</v-icon>
                                            <span class="text-caption">Download all audio</span>
                                        </v-list-item>
                                    </upgrade-wrapper>
                                    <upgrade-wrapper :blocked="!enableExport">
                                        <v-list-item @click.prevent="exportData('xlsx')">
                                            <v-icon left v-if="!enableExport" color="deep-purple accent-3" small>mdi-diamond</v-icon>
                                            <span class="text-caption">XLSX (Excel)</span>
                                        </v-list-item>
                                    </upgrade-wrapper>
                                    <upgrade-wrapper :blocked="!enableExport">
                                        <v-list-item @click.prevent="exportData('csv')">
                                            <v-icon left v-if="!enableExport" color="deep-purple accent-3" small>mdi-diamond</v-icon>
                                            <span class="text-caption">CSV</span>
                                        </v-list-item>
                                    </upgrade-wrapper>
                                </v-list>
                            </v-menu>
                        </div>
                        <div class="text-subtitle-1 grey--text text--darken-1 mb-4">Info Tags</div>
                        <div class="d-flex flex-wrap">
                            <info-tag v-for="tag in infoTags" :key="tag[0]" :label="tag[0]" :value="tag[1]"></info-tag>
                        </div>

                        <v-hover v-if="hasResponseSummarizationFeature && result.summary" v-slot="{ hover }">
                            <v-card class="pa-3 mb-4" outlined>
                                <div style="min-height: 36px;" class="d-flex align-center mb-3">
                                    <v-icon left color="rgb(25, 195, 125)">mdi-shimmer</v-icon>
                                    <span class="text-subtitle-1 grey--text text--darken-1 text-subtitle-1">Summary</span>
                                    <v-spacer></v-spacer>
                                    <v-btn @click="handleCopySummary" v-if="hover" icon>
                                        <v-icon small>mdi-content-copy</v-icon>
                                    </v-btn>
                                </div>
                                <div class="text-body-2">
                                    {{ result.summary}}
                                </div>
                            </v-card>
                        </v-hover>
                        <div v-if="result.metadata">
                            <div class="text-subtitle-1 grey--text text--darken-1 text-subtitle-1 mb-4">Metadata</div>
                            <JsonViewer 
                                :value="result.metadata"
                                copyable
                                theme="json-view-theme"
                                :expand-depth=1
                            >
                            </JsonViewer>
                        </div>
                    </v-card>
                </v-col>
                
                <v-col lg="8" style="overflow-y: auto; height: 100%;" class="pt-8 px-10 d-flex flex-column flex-grow-1">
                    <template v-for="(answerItem, index) in answers">
                        <response-details-answer-item
                            :key="answerItem.question.id"
                            :answerItem="answerItem"
                            :index="index"
                        ></response-details-answer-item>
                    </template>
                </v-col>
            </v-row>
        </div>
        <v-container v-else>
            <div class="d-flex flex-column align-center">
                <img src="https://cdn.voiceform.com/media/blank-forms-illustration.svg" height="264" style="height: 264px;" />
                <div class="mt-6 text-h6">Result not found</div>
                <div class="mt-4 text-body-1">The result has been deleted or doesn't exist.</div>
            </div>
        </v-container> 
</template>
<script>
import ContainerCard from "@/components/dashboard/ContainerCard.vue";
import { mapGetters,  mapMutations} from "vuex";
import axios from 'axios'
import ListItem from '../../../../components/answer/ListItem.vue';
import JsonViewer from 'vue-json-viewer'
import InfoTag from './InfoTag.vue';
import { exportAudio } from '@/api/survey';
import LockedResponse from './LockedResponse.vue';
import useSWRV from 'swrv';
import { useRoute } from 'vue-router/composables';
import fetcher from '@/api/fetcher';
import useSwrvState from '@/utils/useSwrvState'
import UpgradeWrapper from '../../../../components/UpgradeWrapper.vue';

import ResponseSkeletonLoader from "./ResponseSkeletonLoader.vue";
import ResponseDetailsAnswerItem from './ResponseDetailsAnswerItem.vue';


const LEAD_DATA= [
    {
        title: 'Name',
        key: 'name'
    },
    {
        title: 'Email',
        key: 'email'
    },
    {
        title: 'Phone',
        key: 'phone_number'
    },
    {
        title: 'Gender',
        key: 'gender'
    },
    {
        title: 'Age',
        key: 'age'
    },
    {
        title: 'Location',
        key: 'location'
    },
    {
        title: 'Household Income',
        key: 'household_income'
    }
]

export default {
    name: "ResponseDetails",
    components: { 
        ContainerCard, ListItem, JsonViewer, InfoTag, 
         LockedResponse, UpgradeWrapper,
         ResponseSkeletonLoader,
        ResponseDetailsAnswerItem
     },
    data() {
        return {
            resultId: this.$route.params.resultId,
            leadData: LEAD_DATA,
            downloadingAudio: false,
        };
    },
    provide(){
        return {
            result: this.result,
        }
    },
    computed: {
        ...mapGetters({ 
            features: 'auth/features',
            activeMembership: 'teams/activeMembership'
         }),
        answers() {
            return this.resultDetails && this.resultDetails.answers || [];
        },

        enableExport(){
           return this.features && this.features.data_export
        },
        enableAudioExport(){
           return this.features && this.features.audio_export
        },
        hasResponseSummarizationFeature(){
            return !!(this.features && this.features.response_summarization)
        },
        infoTags(){
            let tags = {}
            if(this.result){
                if(this.result.hash){
                    tags = {...tags, hash: this.result.hash}
                }
                if(typeof this.result.information_data === 'object'){
                    // prioritize email and name keys
                    if(this.result.information_data.email){
                        tags = {...tags, email: this.result.information_data.email}
                    }
                    if(this.result.information_data.name){
                        tags = {...tags, name: this.result.information_data.name}
                    }
                    tags = {...tags, ...this.result.information_data}
                }
            }
            return Object.entries(tags)
        },
        showDownload(){
            if(this.resultDetails && this.resultDetails.answers && this.resultDetails.answers.length > 0){
                return !!this.resultDetails.answers.find(answerItem =>{ 
                    if(answerItem && answerItem.answers && answerItem.answers.length > 0){
                        return !!(answerItem.answers.find(answer => {
                            return answer && !!answer.file_path
                        }))
                    }
                    return false
                } )
            }
            return false
        },
        result(){
            return this.resultDetails && this.resultDetails.result
        }
    },
    methods: {
        ...mapMutations(['showSnackBar']),
        async exportData(type) {
            let response = await axios.get(
                `/api/result/${this.$route.params.resultId}/export?type=${type}`,
                {
                    responseType: "blob",
                }
            );
            const contentType = response.headers["content-type"];
            const contentDisposition = response.headers["content-disposition"];
            const data = response.data;
            const fileName = contentDisposition.substring(
                contentDisposition.indexOf("filename=") + "filename=".length
            );
            const fileUrl = window.URL.createObjectURL(
                new Blob([data], { type: contentType })
            );
            const fileLink = document.createElement("a");
            fileLink.href = fileUrl;
            fileLink.setAttribute("download", fileName);
            document.body.appendChild(fileLink);
            fileLink.click();
            document.body.removeChild(fileLink);
        },
        async downloadAudio() {
            try {
                this.downloadingAudio=true
                await exportAudio(this.$route.params.resultId, 'result')
                this.downloadingAudio=false
            } catch (error) {
                this.downloadingAudio=false
                this.showSnackBar({
                    text: 'Cannot download audio.', 
                    color: 'error', 
                    timeout: 2000,
                })
            }
        },
        handleCopySummary() {
            try {
                // The text to be copied
                const textToCopy = this.result.summary;

                // Use Clipboard API's writeText method to copy the text
                navigator.clipboard.writeText(textToCopy)
                    .then(() => {
                        // The promise resolves once text is copied
                        this.showSnackBar({
                            text: 'Summary copied to clipboard.',
                            color: 'success',
                            timeout: 2000,
                        });
                    })
                    .catch((error) => {
                        // Log error if copying fails
                        console.error('Error copying text: ', error);
                        this.showSnackBar({
                            text: 'Error: Could not copy summary.',
                            color: 'error',
                            timeout: 2000,
                        });
                    });
            } catch (error) {
                // Handle any other errors
                console.error('Error: ', error);
                this.showSnackBar({
                    text: 'Unexpected error.',
                    color: 'error',
                    timeout: 2000,
                });
            }

        }
    },
    setup() {
            const route = useRoute();
            // caching only first page
            const { data, error, isValidating, mutate } = useSWRV(
                () => {
                    return `/api/analytics/${route.params.surveyId}/results/${route.params.resultId}`;
                },
                fetcher
            )
            const { state, STATES } = useSwrvState(data, error, isValidating)
            return {
                state, STATES,
                resultDetails: data,
                error,
                isValidating,
                revalidateResult: mutate
            }
        },
};
</script>
<style lang="scss" scoped>
.result-id{
    border-radius: 4px;
    padding: 4px 12px;
    background-color: var(--v-primary-lighten5);
}
.answers-container{
    .header {
        font-size: 10px;
    }
    .transcript{
        font-size: 14px;
    }
    font-size: 16px;
}
.chat-container{
    height: 100vh;
    max-height: calc(100vh - 116px);
}
</style>
