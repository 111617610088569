<template>
    <div>
        <div class="sticky-top">
            <v-card class="py-5 px-1 d-flex align-center rounded-0" elevation="0" style="background: var(--v-grey-lighten5);">
                <v-divider></v-divider>
                <span class="px-6 text-body-2 grey--text">{{ dateHeader }}</span>
                <v-divider></v-divider>
            </v-card>
            <v-card elevation="0">
                <v-row no-gutters class="py-4" style="background-color: white">
                    <v-col class="grey--text text-caption" style="min-width: 232px; max-width: 232px;">
                        <span class="pl-4">Audio</span>
                    </v-col>
                    <v-col class="grey--text text-caption" cols="6">Transcript</v-col>
                    <v-col class="grey--text text-caption">Sentiment</v-col>
                </v-row>
            </v-card>
        </div>
        <v-card class="rounded-0" elevation="0">
            <audio-item v-for="answer in answers" :key="answer.id" :answer="answer" @playAudio="playAudio"></audio-item>
        </v-card>
    </div>
</template>

<script>
import AudioItem from './AudioItem.vue';

export default {
    props: {
        answers: Array,
        dateHeader: String
    },
    components: { AudioItem },
    methods: {
        playAudio(answer) {
            return this.$emit('playAnswerAudio', answer);
        }
    }
};
</script>

<style lang="scss" scoped>
.sticky-top {
    position: sticky;
    top: 112px;
    z-index: 2; // overwrite other v-cards (whose z-idx is 1)
}
</style>
