<template>
    <div>
        <div class="sticky-top">
            <v-card class="py-5 px-1 d-flex align-center rounded-0" elevation="0" style="background: var(--v-grey-lighten5);">
                <v-divider></v-divider>
                <span class="px-6 text-body-1 grey--text">{{ dateHeader }}</span>
                <v-divider></v-divider>
            </v-card>
            <v-card elevation="0">
                <v-row no-gutters class="py-4" style="background-color: white">
                    <v-col class="grey--text text-caption" style="margin-left: 100px; max-width: 356px">{{isFileUpload?'Files': 'Answer'}}</v-col>
                    <v-col class="grey--text text-caption">Date Added</v-col>
                </v-row>
            </v-card>
        </div>

        <v-card v-if="isFileUpload" class="rounded-0" elevation="0">
            <regular-file-attachment v-for="answer in answers" :key="`regular-file-item-${answer.id}`" :answer="answer" />
        </v-card>
        <v-card v-else class="rounded-0" elevation="0">
            <regular-item v-for="answer in answers" :key="`regular-list-item-${answer.id}`" :answer="answer" />
        </v-card>
    </div>
</template>

<script>
import RegularFileAttachment from './RegularFileAttachment.vue';
import RegularItem from './RegularItem.vue';

export default {
    inject: ['question'],
    props: {
        answers: Array,
        dateHeader: String
    },
    components: { RegularItem, RegularFileAttachment },
    computed: {
        isFileUpload() {
            return this.question && this.question.type==='file-upload';
        }
    }
};
</script>

<style lang="scss" scoped>
.sticky-top {
    position: sticky;
    top: 112px;
    z-index: 2; // overwrite other v-cards (whose z-idx is 1)
}
</style>
