<template>
    <results-layout>
        <v-navigation-drawer v-if="results" permanent left class="d-flex nav-drawer" clipped width="350" app>
            <div class="pt-12 pb-4 px-6">
                <div class="mt-6 text-subtitle-1">Form Responses</div>
            </div>
            <div class="pt-4 px-6 pb-12 responders-list-container">
                <template v-if="results && results.length">
                    <response-side-item
                        v-for="(result, index) in results" 
                        :resultIndex="resultsFetch.total-index"
                        :key="result.id" 
                        :result="result"
                        @delete="handleResultDelete(result)"
                        @copyLink="handleCopyLink(result)"
                        @click="handleResultSelect(result)"
                    >
                    </response-side-item>
                </template>
                <div v-if="showLoadMore" class="d-flex pa-6 full-width flex-grow-1 align-center justify-center" v-intersect="{
                    handler: onIntersect,
                    options: {
                        threshold: 0,
                        rootMargin: '0px 0px 400px 0px',
                    }
                }">
                    <v-btn :loading="loadingMore" :disabled="loadingMore" elevation="0" @click="loadMore">Show More</v-btn>
                </div>
            </div>
        </v-navigation-drawer>
        <div>
            <response-details v-if="results && $route.params.resultId" :key="`response-${$route.params.resultId}`"></response-details>
            <no-answers v-else></no-answers>
        </div>
        <v-dialog
                v-model="deleteDialog"
                width="500"
                @click:outside="closeDeleteDialog"
            >
            <v-card class="remove-dialog pt-4 pb-3 px-6">
                <div class="text-h6">
                    Delete Response
                </div>
                <p>Are you sure you want to delete <b>{{deletingResult && (getNameFromResult(deletingResult) || `Responder #${deletingResult.id}`)}}</b> response? Once deleted, you will not be able to recover this response or its contents.</p>
                <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                    text
                    @click="closeDeleteDialog"
                >
                    Cancel
                </v-btn>
                <v-btn
                    color="red"
                    dark
                    :elevation="0"
                    @click="deleteResponse"
                    :loading="deleteLoading"
                    :disabled="deleteLoading"
                >
                    Yes, Delete it
                </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </results-layout>
</template>

<script>
import { mapActions, mapMutations } from 'vuex'
import { questionIcons } from '../../../../configs/questionTypes';
import ResultsLayout from '../../../../layouts/ResultsLayout.vue'
import QuestionDetails from './QuestionDetails.vue';
import NoQuestions from './NoQuestions.vue'
import ResponseSideItem from './ResponseSideItem.vue';
import NoAnswers from './NoAnswers.vue';
import ResponseDetails from './ResponseDetails.vue';
import { destroyResult } from '@/api/survey';
import {ref} from 'vue'
import useSWRV from 'swrv';
import { useRoute } from 'vue-router/composables';
import fetcher from '@/api/fetcher';
import useSwrvState from '@/utils/useSwrvState'

export default {
    data(){
        return {
            questionIcons,
            deleteDialog: false,
            deletingResult: null,
            deleteLoading: false,
            loadingMore: false,
            nextCursor: null,
        }
    },
    components: { ResultsLayout, QuestionDetails, NoQuestions, ResponseSideItem, NoAnswers, ResponseDetails},
    computed: {
        results() {
            return this.resultsFetch && this.resultsFetch.data || null;
        },
        showLoadMore(){
            return ![this.STATES.PENDING].includes(this.state) && this.results && this.results.length && this.results.length < this.resultsFetch.total
        },
    },
    watch:{
        'resultsFetch': {
            immediate: true,
            handler(){
              if(this.$route.name === 'dashboard.voiceform.results.respondents' && this.resultsFetch && this.resultsFetch.data.length && this.$route.params && this.$route.params.surveyId && !this.$route.params.resultId){
                this.$router.replace({
                    name: 'dashboard.voiceform.results.respondents', 
                    params: {
                        surveyId: this.$route.params.surveyId,
                        resultId: this.resultsFetch.data[0].id
                    }
                })
              }
            }
        }
    }, 
    methods: {
        ...mapActions({
            setSurvey: 'survey/setSurvey'
        }),
        ...mapMutations(['showSnackBar']),
        getEmailFromAnswer(result) {
            let answer = result.answers.find(
                (answer) => answer.value && answer.value.type == "email"
            );
            return answer ? answer.value.value : "";
        },
        getNameFromResult(result){
            return result && result.information_data && (result.information_data.name || result.information_data.first_name || result.information_data.last_name || '')
        },
        handleResultDelete(result){
            this.deleteDialog = true
            this.deletingResult = result
        },
        handleResultSelect(result){
            this.selectedResult = result
            this.$router.replace({
                name: 'dashboard.voiceform.results.respondents', 
                params: {
                    surveyId: this.$route.params.surveyId,
                    resultId: result.id
                }
            })
        },
        async handleCopyLink(result) {
            try {
                await navigator.clipboard.writeText(window.location.origin + "/dashboard/voiceform/"
                    + this.$route.params.surveyId + "/results/respondents/" + result.id);
            } catch (err) {
                console.error(err);
            }
            this.showSnackBar({
                text: "Question result link copied.",
                color: "success",
                timeout: 1000,
            });
        },
        closeDeleteDialog(){
            this.deleteDialog = false
            this.deletingResult = null
            this.deleteLoading = false
        },
        async deleteResponse(){
            try {
                this.deleteLoading = true
                const indexToRemove = this.results.findIndex(result => result.id === this.deletingResult.id)
                await destroyResult(this.deletingResult.id)
                this.deleteLoading = false
                this.closeDeleteDialog()
                if (indexToRemove !== -1) { // If the element is found in the array
                    this.resultsFetch.data.splice(indexToRemove, 1); // Remove the element
                    this.resultsFetch.total -= 1
                }
                if(this.results && this.results.length){
                    this.$router.replace({path: `/dashboard/voiceform/${this.$route.params.surveyId}/results/respondents/${this.results[0].id}`})
                }

                if(this.selectedResult && this.selectedResult.id === this.deletingResult.id){
                    this.selectedResult = null
                }

                this.showSnackBar({
                    color: "success",
                    timeout: 6000,
                    text: "Removed response.",
                });
            } catch (error) {
                this.deleteLoading = false
                console.error('deleteResponse', error);
            }
        },
        onIntersect (entries) {
            if(entries[0].isIntersecting){
                this.loadMore()
            }
        },
        async loadMore() {
            try {
                if (this.results && this.results.length) {
                    this.loadingMore = true;

                    if(!this.nextCursor && this.resultsFetch.next_cursor){
                        this.nextCursor = this.resultsFetch.next_cursor
                    }

                    // Prepare the URL with the next cursor if available
                    let url = `/api/analytics/${this.$route.params.surveyId}/results?limit=${this.limit}`;
                    if (this.nextCursor) {
                        url += `&cursor=${this.nextCursor}`;
                    }

                    // Fetch the next page
                    const nextPageData = await fetcher(url);
                    if (nextPageData && nextPageData.data && nextPageData.data.length) {
                        // Update the cache with the next page's data
                        this.resultsFetch.data = [...this.resultsFetch.data, ...nextPageData.data];
                        this.resultsFetch.total = nextPageData.total;

                        // Update the next cursor
                        this.nextCursor = nextPageData.next_cursor;
                    }
                    this.loadingMore = false;
                }
            } catch (error) {
                this.loadingMore = false
                this.loadedPageNumber-=1
                this.showSnackBar({
                    text: 'Cannot load more results. Please try again later.', 
                    color: 'error', 
                    timeout: 2000,
                })
            }
        },
        
    },
    setup() {
        const route = useRoute();
        const limit = ref(10)
        // caching only first page
        const { data, error, isValidating, mutate } = useSWRV(
            () => `/api/analytics/${route.params.surveyId}/results?limit=${limit.value}`,
            fetcher,
            {
                revalidateOnFocus: false,
            }
        )
        const { state, STATES } = useSwrvState(data, error, isValidating)

        return {
            state, STATES,
            resultsFetch: data,
            error,
            isValidating,
            revalidateResults: mutate,
            limit
        }
    },
}
</script>

<style lang="scss" scoped>
.nav-drawer::v-deep .v-navigation-drawer__content{
    overflow: hidden;
    display: flex;
    flex-direction: column;
}
.responders-list-container{
    overflow-y: auto;
    width: 100%;
    flex: 1;
}
</style>