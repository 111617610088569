export function getRandomInt(min, max) {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min) + min); // The maximum is exclusive and the minimum is inclusive
}

export function getRandomDecimal(min, max) {
    return Math.random() * (max - min) + min; // The maximum is exclusive and the minimum is inclusive
}
export function timeNumerictoString(timeNumeric) {
    if (timeNumeric === 0) return '0s';

    const minute = Math.floor(timeNumeric / 60);
    const second = timeNumeric % 60;
    return (minute > 0 ? (minute + 'm') : '') + ' ' + (second > 0 ? (second + 's') : '');
}
