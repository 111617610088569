<template>
    <div v-if="answer">
        <v-card color="grey lighten-3" class="pa-4 mb-4" :elevation="0" v-if="answer.sentiment && answer.sentiment_score" >
            <v-row no-gutters>
                <v-col md="3">
                    <span class="text-subtitle-2">Total Sentiment</span>
                </v-col>
                <v-col md="1" class="chip-col">
                    <sentiment-chip small :sentimentValue="answer.sentiment" />
                </v-col>
                <v-col md="1" class="d-flex justify-end percentage-col mr-4">
                    <span class="text-subtitle-2">{{formatScoreValue(answerSentimentScore)}}</span>
                </v-col>
                <v-col class="d-flex align-center">
                    <keywords-sentiment-score-progress :value="answerSentimentScore" />
                </v-col>
            </v-row>
        </v-card>
        <template v-if="keywords.length">
            <v-row class="px-4 mb-4" no-gutters v-for="keyword in keywords" :key="keyword.text">
                <v-col md="3">
                    <span class="text-body-2">{{formatKeywordText(keyword)}}</span>
                </v-col>
                <v-col md="1" class="chip-col" v-if="keyword.sentiment">
                    <sentiment-chip small :sentimentValue="keyword.sentiment.label" />
                </v-col>
                <v-col md="1" class="d-flex justify-end percentage-col mr-4" v-if="keyword.sentiment">
                    <span class="text-subtitle-2">{{formatScoreValue(keyword.sentiment.score)}}</span>
                </v-col>
                <v-col class="d-flex align-center" v-if="keyword.sentiment">
                    <keywords-sentiment-score-progress :value="keyword.sentiment.score" />
                </v-col>
            </v-row>
        </template>
    </div>
</template>
<script>
import SentimentChip from '../sentiment/SentimentChip.vue'
import KeywordsSentimentScoreProgress from './KeywordsSentimentScoreProgress.vue'
export default {
  components: { SentimentChip, KeywordsSentimentScoreProgress },
    name: "KeywordsSentimentScoresList",
    props: {
        answer: {
            required: true,
        },
    },
    computed: {
        keywords(){
            return this.answer.keywords
        },
        answerSentimentScore(){
            return this.answer && this.answer.sentiment_score && Number(this.answer.sentiment_score) || 0
        }
    },
    methods: {
        formatScoreValue(score){
            const percent = score*100
            return `${percent.toFixed(0)}%`
        },
        formatKeywordText(keyword){
            if(keyword.count && keyword.count>1){
                return `${keyword.text} (${keyword.count})`
            }
            return keyword.text
        }
    }
}
</script>
<style lang="scss" scoped>
.chip-col{
    min-width: 100px;
}
.percentage-col{
    min-width: 40px;
}
</style>