<template>
    <v-hover v-slot="{ hover }">
        <div class="d-flex justify-end flex-grow-1">
            <v-btn @click="downloadFile" :loading="downloading" :disabled="downloading" v-if="hover || downloading" icon class="ma-2">
                <v-icon>mdi-download</v-icon>
            </v-btn>
            <v-card max-width="300" outlined style="overflow: hidden;" background-color="white" rounded elevation="0" class="mb-4">
                <!-- image -->
                <div v-if="file.type && file.type.includes('image')">
                    <v-img  :src="file.url" :alt="file.name" />
                </div>
                <!-- video -->
                <div v-else-if="file.type && file.type.includes('video')" style="display: flex; align-items: center; justify-content: center; height: 100%;">
                    <video ref="videoElement" controlsList="nodownload" controls :src="file.url" :alt="file.name" style="max-width: 100%;" />
                </div>
                <div style="min-height: 40px;" class="d-flex align-center pa-3" v-else>
                    <v-icon>mdi-file</v-icon>
                    <div class="ml-2 text-body-2">{{ file.name }}</div>
                </div>

                <!-- add text for vide and image -->
                <div v-if="file.name && (file.type.includes('image') || file.type.includes('video'))" class="px-3 py-4">
                    <div class="text-body-2">{{ file.name }}</div>
                </div>
            </v-card>
        </div>
    </v-hover>
</template>

<script>
import DownloadApi from '@/api/DownloadApi';
import { mapMutations } from 'vuex';


export default {
    name: 'ResultsFileAttachmentMediaItem',
    props: {
        file: Object
    },
    data(){
        return {
            downloading: false
        }
    },
    methods:{
        ...mapMutations(['showSnackBar']),
        async downloadFile(){
            try {
                this.downloading = true;
                const response = await DownloadApi.download(this.file.url);
                const blob = new Blob([response.data], { type: response.headers['content-type'] });
                const link = document.createElement('a');
                link.href = URL.createObjectURL(blob);
                
                // Extracting filename from the Content-Disposition header (optional)
                const contentDisposition = response.headers['content-disposition'];
                let filename = 'downloaded_file';
                if (contentDisposition) {
                    const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
                    const matches = filenameRegex.exec(contentDisposition);
                    if (matches != null && matches[1]) {
                        filename = matches[1].replace(/['"]/g, '');
                    }
                }
                
                link.download = filename;
                link.click();
                URL.revokeObjectURL(link.href);
            } catch (error) {
                console.error(error);
            }finally{
                this.downloading = false;
            }
        },
        pauseOtherMedia(event) {
            const playingMedia = document.querySelectorAll('audio, video');
            playingMedia.forEach(media => {
                if (media !== event.target) {
                    media.pause();
                }
            });
        }
    },
    mounted() {
        if(this.$refs.videoElement){
            this.$refs.videoElement.addEventListener('play', this.pauseOtherMedia);
        }
    },
    beforeDestroy() {
        if(this.$refs.videoElement){
            this.$refs.videoElement.removeEventListener('play', this.pauseOtherMedia);
        }
    },
}
</script>