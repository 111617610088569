<template>
    <v-menu 
        offset-y
        :close-on-content-click="false"
        v-model="menu"
    >
            <template v-slot:activator="{ on, attrs }">
                <v-btn
                    v-bind="attrs"
                    v-on="on"
                    color="secondary"
                    outlined
                    min-width="135"
                    class="justify-start"
                >
                    <v-icon :color="value" left>mdi-water</v-icon>
                    {{value}}
                </v-btn>
            </template>
        <div v-on:mousedown.stop="handleMouseDown" v-on:mouseup="handleMouseUp">
            <v-color-picker
                flat
                mode="hexa"
                :value="value"
                @input="val=>$emit('input', val)"
            ></v-color-picker>
        </div>
    </v-menu>
</template>

<script>
export default {
    props: ['value'],
    data(){
        return {
            tempColor: this.value,
            menu: false
        }
    },
    watch: {
        menu(val){
            if(val === false && this.tempColor !== this.value){
                this.$emit('change', this.value)
                this.tempColor = this.value
            }
        }
    },
    methods: {
        handleMouseDown(){
            this.tempColor= this.value
        },
        handleMouseUp(){
            if(this.tempColor !== this.value){
                this.$emit('change', this.value)
            }
        }
    }
}
</script>