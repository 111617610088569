<template>
    <div class="d-flex mb-2">
        <v-avatar size="32" class="mt-1 mr-2">
            <img
                src="https://cdn.voiceform.com/media/voiceform-avatar-logo.png"
                alt="Voiceform Logo"
            >
        </v-avatar>
        <v-card max-width="80%" elevation="0" color="primary lighten-5" class="pa-4 rounded-xlg">
            <div class="text-subtitle-1">
                <span class="text-body-1 grey--text text--darken-2 pr-2">{{question.order+1}}. </span>
                <v-chip dark color="deep-purple accent-3" v-if="loopIndex" class="text-caption mr-2" small label><v-icon small left>mdi-sync</v-icon> Loop {{loopIndex}}</v-chip>
                <span v-if="!question.is_optional" class="error--text">*</span>
                {{questionText}}
                <v-chip class="ml-1" dark small color="primary">{{questionType}}</v-chip>
            </div>
        </v-card>
        <v-spacer></v-spacer>
    </div>
</template>

<script>
import Handlebars from "handlebars";

export default {
    name: 'ResponseDetailsQuestion',
    props: {
        question: {
            required: true
        },
        loopIndex: {
            type: Number,
            default: 0
        },
        loop_item: {
            default: null
        }
    },
    computed:{
        questionType(){
            if(this.question){
               return this.question.type && this.question.type.replace(/-/g, ' ')
            }

            return ''
        },
        questionText(){
            if(this.question.type === 'description-slide' || !this.question.text){
                return ''
            }

            if(this.loop_item !== null){
                const template = Handlebars.compile(this.question.text, {noEscape: true})
                return template({loop_item: this.loop_item})
            }

            return this.question.text
        }
    }
}
</script>

<style lang="scss" scoped>
.loop-index{
    background-color: var(--v-primary-lighten5);
    padding: 0 5px;
    color: var(--v-white-base);
}
</style>