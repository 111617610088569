<template>
    <v-dialog 
        :value="value" 
        @input="(val)=>$emit('input', val)"
        width="100%"
        max-width="1038px"
        persistent
    >
        <v-card height="700px" class="relative" rounded>
            <div class="d-flex fill-height">
                <div class="pt-8 d-flex flex-column flex-grow-1">
                        <div>
                            <v-btn class="mx-4 mb-4" @click="$emit('input', false)" color="primary" text>
                                <v-icon left>mdi-close</v-icon>
                                Close
                            </v-btn>
                            <v-tabs class="px-4" v-model="tab">
                                <v-tab class="text-subtitle-2 tab">
                                    Design
                                </v-tab>
                                <v-tab class="text-subtitle-2 tab">
                                    Transcript
                                </v-tab>
                            </v-tabs>
                        </div>
                        <v-divider></v-divider>
                        <div class="tabs-container">
                            <v-tabs-items v-model="tab">
                                <v-tab-item>
                                    <v-card flat class="px-4 pt-4 d-flex flex-grow-1">
                                        <properties></properties>
                                    </v-card>
                                </v-tab-item>
                                <v-tab-item>
                                    <v-card flat class="px-4 pt-4 d-flex flex-grow-1">
                                        <transcript-edits></transcript-edits>
                                    </v-card>
                                </v-tab-item>
                            </v-tabs-items>
                        </div>
                        <v-alert v-model="alert" @input="handleDismiss" class="ma-4 text-body-2" color="deep-purple" border="left" text dismissible>
                            Welcome to Voiceform Visualize! This new feature helps you turn your voice responses into stunning visuals for
                            presentations, social posts, testimonials and more.<br/><br/>
                            We’ve only released this feature to a small subset of users, and wanted to make sure you were one of them. If
                            anything unexpected happens or if you find any issues, please let us know. Happy Creating!
                        </v-alert>
                </div>

                <v-fade-transition>
                    <div v-if="value" class="d-flex flex-column pa-8 primary lighten-5">
                        <preview-container></preview-container>
                    </div>
                </v-fade-transition>
            </div>
        </v-card>
    </v-dialog>
</template>
<script>
import PreviewContainer from './PreviewContainer.vue'
import Properties from './DesignProperties.vue'
import TranscriptEdits from './TranscriptEdits.vue'

const DISMISS_ALERT_KEY= 'dismiss-visualization-beta-alert'
export default {
    components: { PreviewContainer, Properties, TranscriptEdits },
    name: 'ExportAsVideoDialog',
    props: ['value'],
    data(){
        return {tab: null, alert: localStorage.getItem(DISMISS_ALERT_KEY)? false : true}
    },
    methods: {
        handleDismiss(val){
            if(val===false){
                localStorage.setItem(DISMISS_ALERT_KEY,'true')
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.left-side{
    max-height:"700px"
}
.tab{
    text-transform: capitalize;
}
.tabs-container{
    overflow-y: auto;
    width: 100%;
    flex: 1;
}
</style>