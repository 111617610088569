<template>
    <div class="d-flex mb-2">
        <v-avatar size="32" :color="failed?'red accent-3':'rgb(25, 195, 125)'" class="mt-1 mr-2">
            <v-icon dark>{{failed?'mdi-robot-dead': 'mdi-robot'}}</v-icon>
        </v-avatar>
        <v-card v-if="failed" max-width="80%" elevation="0" class="px-4 py-1 rounded-xlg d-flex align-center" color="red lighten-4">
            <div class="text-body-2">Failed to follow up</div>
        </v-card>
        <v-card v-else max-width="80%" elevation="0" color="#75a99b30" class="pa-4 rounded-xlg">
            <div class="text-body-2">
                <span v-if="!question.is_optional" class="error--text">*</span>
                {{followupText}}
                <a @click="showOriginal=!showOriginal" v-if="followupTranslation" class="text-caption grey--text pt-1 d-block">{{ showOriginal? 'View Translation' : 'View Original' }}</a>
            </div>
        </v-card>
        <v-spacer></v-spacer>
    </div>
</template>

<script>
export default {
    name: 'ProbingQuestion',
    data() {
        return {
            showOriginal: false
        }
    },
    props: {
        followup: {
            required: true
        },
        followupTranslation: {
            default: ''
        },
        question: {
            required: true
        },
        failed: {
            default: false
        }
    },
    computed:{
        followupText(){
            return !this.showOriginal && this.followupTranslation || this.followup
        },
        questionType(){
            if(this.question){
               return this.question.type && this.question.type.replace(/-/g, ' ')
            }

            return ''
        }
    }
}
</script>