<template>
    <v-expand-transition>
        <div v-if="usage">
            <div class="text-subtitle-1">Your account usage</div>
            <div class="text-body-2 my-4"><span :class="['subtitle-1', {'error--text': usage.total_seats>usage.seats_limit}]">{{usage.total_seats}}</span>/{{usage.seats_limit}} available {{usage.seats_limit>1?'seats':'seat'}}</div>
            <v-fade-transition>
                <v-alert
                    v-if="usageAlertType"
                    class="mt-4"
                    :color="usageColor"
                    text
                >
                    <div class="text-subtitle-2">
                        {{usageAlertTitle}}
                    </div>
                    <div class="mt-4 text-caption">{{usageAlertMessage}}</div>
                </v-alert>
            </v-fade-transition>
            <div class="mt-2 text-body-2">Responses collected</div>
            <v-progress-linear class="mt-2" height="4" rounded :color="usageColor" :value="usageProgressValue"></v-progress-linear>
            <div class="mt-1 d-flex align-flex-end">
                <div class="text-body-1">{{usage.number_of_submissions}}</div>
                <div style="margin-top: 2px" class="text-body-2 grey--text text--darken-1">/{{usage.submission_limit}}</div>
            </div>
            <div class="mt-4 text-caption grey--text text--darken-1">Resets on {{usageResetDate}}</div>
            <router-link v-if="showBilling" :to="{name: 'dashboard.upgrade'}">
                <v-btn class="mt-3 ml-n4" text color="primary">
                    <v-icon left>mdi-plus-circle</v-icon>
                    Increase limit
                </v-btn>
            </router-link>
        </div>
    </v-expand-transition>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
export default {
    computed: {
        ...mapGetters({
            usage: 'billing/usage',
            user: 'auth/user'
        }),

        usageProgressValue(){
            try {
                return Math.round(this.usage.number_of_submissions/this.usage.submission_limit*100)
            } catch (error) {
                console.error('usageProgressValue error', error);
                return undefined
            }
        },

        usageAlertType(){
            if(this.usage.number_of_submissions > this.usage.submission_limit){
                return 'error'
            }
            if(this.usageProgressValue > 90){
                return 'warning'
            }
            return ''
        },

        usageColor(){
            switch (this.usageAlertType) {
                case 'error':
                   return 'error' 
                case 'warning':
                   return 'warning' 
                default:
                    return 'primary';
            }
        },

        usageAlertTitle(){
            if(this.usageAlertType==='error'){
                return "You've reached your monthly limit"
            }
            return "Close to response limit"
        },

        usageAlertMessage(){
            if(this.usageAlertType==='error'){
                return "Additional responses will be locked."
            }
            return `You're at ${this.usageProgressValue}% of your monthly response limit.`
        },

        usageResetDate(){
            if(this.usage && this.usage.current_period_end){
                return this.$date(this.usage.current_period_end).format('MMM D')
            }
            return ''
        },
        showBilling(){
            return !!this.user && this.user.billing
        },
    },
    methods: {
        ...mapActions({
            fetchUsage: 'billing/fetchUsage'
        })
    },
    created(){
        this.fetchUsage()
    }
}
</script>