<template>
    <v-row no-gutters class="d-flex align-top py-8">
        <template v-if="answer && !answer.expires_at">
            <!-- serial label -->
            <v-col style="max-width: 100px">
                <v-btn fab small elevation="0" color="grey lighten-4" style="pointer-events: none" class="ml-6">
                    <span class="text--primary">{{ answer.position }}</span>
                </v-btn>
            </v-col>
    
            <!-- answer content -->
            <v-col style="min-width: 340px; max-width: 340px;" class="mr-4">
                <v-tooltip v-if="['processing', 're-processing'].includes(answer.status)" bottom
                    :max-width="260">
                    <template v-slot:activator="{ on, attrs }">
                        <v-chip label small color="primary lighten-2" v-bind="attrs"
                            v-on="on">Processing</v-chip>
                    </template>
                    <span>Some responses require a little more time to process. Refresh this page or check back
                        soon 🙌</span>
                </v-tooltip>
                <template v-else v-for="file in files">
                    <regular-file-attachment-media-item v-if="file && file.type" :file="file" :key="file.url"></regular-file-attachment-media-item>
                </template>
            </v-col>
    
            <!-- date added -->
            <v-col class="mt-2">
                <div class="grey--text text-caption">{{ formattAnswerDate(answer.created_at, false) }}</div>
            </v-col>
    
            <!-- respondent info -->
            <v-col class="text-center mt-2">
                <respondent-info :answer="answer"></respondent-info>
            </v-col>
    
            <!-- liking -->
            <v-col style="max-width: 100px">
                <v-btn icon @click="handleLike">
                    <v-icon v-if="liked" color="red">mdi-heart</v-icon>
                    <v-icon v-else>mdi-heart-outline</v-icon>
                </v-btn>
            </v-col>
        </template>
        <template v-else>
            <v-col style="max-width: 100px">
                <v-btn fab small elevation="0" color="grey lighten-4" style="pointer-events: none" class="ml-6">
                    <span class="text--primary">{{ answer.position }}</span>
                </v-btn>
            </v-col>
            <v-col style="min-width: 340px; max-width: 340px;" class="mr-4 mt-1">
                <v-chip class="mt-2" small elevation="0" color="deep-orange lighten-1" dark label>
                    <v-icon small left>
                        mdi-lock-outline
                    </v-icon>
                    expires {{ $date(answer.expires_at).fromNow() }}
                </v-chip>
            </v-col>
            <!-- date added -->
            <v-col class="mt-2">
                <div class="grey--text text-caption">{{ formattAnswerDate(answer.created_at, false) }}</div>
            </v-col>
            <v-col>
                <v-dialog width="500">
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn v-on="on" v-bind="attrs" text elevation="0" color="primary" class="mx-6" >
                            <v-icon left>mdi-lock-open-variant-outline</v-icon>
                            Unlock
                        </v-btn>
                    </template>
                    <v-card class="pa-8">
                        <locked-response :expiresAt="answer.expires_at" :submissionCount="answer.submission_count_current_period"></locked-response>
                    </v-card>
                </v-dialog>
            </v-col>
        </template>
    </v-row>
</template>

<script>
import RespondentInfo from '../RespondentInfo.vue';
import dayjs from 'dayjs';
import LockedResponse from '../../../views/dashboard/voiceform/results/LockedResponse.vue';
import { toggleLikeAnswer } from '@/api/survey';
import RegularFileAttachmentMediaItem from './RegularFileAttachmentMediaItem.vue';

export default {
    name: 'RegularItem',
    props: {
        answer: Object
    },
    components: { RespondentInfo, LockedResponse, RegularFileAttachmentMediaItem },
    data() {
        return {
            expandTranscript: false,
            contentOverflow: false,
            liked: this.answer.liked
        };
    },
    watch: {
        'answer.liked'(val) {
            this.liked = val;
        }
    },
    computed: {
        files(){
            return this.answer?.value?.value || [];
        }
    },
    methods: {
        formattAnswerDate(dateString, dateOnly = false) {
            return dayjs(dateString).format(dateOnly ? 'YYYY-MM-DD' : 'YYYY-MM-DD hh:mma');
        },
        handleLike() {
            try {
                this.liked = !this.liked
                toggleLikeAnswer(this.answer.id, !this.answer.liked)
                this.$emit('onLikeToggle', this.answer, !this.answer.liked);
            } catch (error) {
                console.error('handleLike',error);
            }
        }
    },
    mounted() {
        if(this.$refs.answerContent){
            this.contentOverflow = this.$refs.answerContent.clientWidth < this.$refs.answerContent.scrollWidth;
        }
    }
};
</script>

<style scoped lang="scss">
.answer-chip {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}
</style>