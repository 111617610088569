<template>
    <v-menu 
        offset-y
        v-model="menu"
        max-width="400"
        max-height="300"
    >
        <template v-slot:activator="{ on, attrs }">
            <v-btn 
                v-bind="attrs"
                v-on="on"
                outlined
                min-width="75"
            >
                <img class="ml-n2"  alt="selected reaction icon" width="24" height="24" style="width: 24px; height: 24px" :src="selectedIcon"/>
                <v-spacer></v-spacer>
                <v-icon class="ml-2" size="24" right>mdi-menu-down</v-icon>
            </v-btn>
        </template>

        <div class="white pa-3">
            <v-row>
                <v-col v-for="(emoji, i) in emojis" :key="`emoji-${i}`" sm="2">
                    <v-btn icon elevation="0" width="42" height="42" @click="handleIconSelect(emoji)">
                        <img width="24" height="24" style="width: 24px; height: 24px"  :src="emoji" />
                    </v-btn>
                </v-col>
            </v-row>
        </div>
    </v-menu>

</template>
<script>
import emojis from './emojis-list.json'
export default {
    props: ['value'],
    data(){
        return {
            emojis,
            menu: false,
        }
    },
    computed: {
        selectedIcon(){
            if(!this.value){
                return 'https://cdn.voiceform.com/media/emojis/107-heart-4.png'
            }
            return this.value
        }
    },
    methods:{
        handleIconSelect(iconSource){
            this.$emit('input', iconSource)
        }
    }
}
</script>