<template>
    <audio ref="audioElement" controls controlsList="nodownload" @play="pauseOthers">
        <source :src="source" type="audio/mpeg">
        Your browser does not support the audio element.
    </audio>
</template>

<script>
export default {
    name: "ResultsAudioPlayer",
    props: {
        source: {
            required : true,
            type: String
        }
    },
    mounted() {
        if (!this.$root.audioElements) {
            this.$root.audioElements = [];
        }
        this.$root.audioElements.push(this.$refs.audioElement);
    },
    beforeDestroy() {
        this.$root.audioElements = this.$root.audioElements.filter(
            (el) => el !== this.$refs.audioElement
        );
    },
    methods: {
        pauseOthers() {
            this.$root.audioElements.forEach((el) => {
                if (el !== this.$refs.audioElement && !el.paused) el.pause();
            });
        },
    },
};
</script>