<template>
    <v-hover v-if="file" v-slot="{ hover }">
        <v-card :to="{
            ...$route,
            query: {
                ...$route.query,
                fileViewerUrl: file.url,
                fileViewerType: file.type,
                fileViewerName: fileName
            }
        }" height="38" elevation="0" class="d-flex px-3 align-center" :color="hover?'grey lighten-3':'transparent'">

            <v-img class="rounded" v-if="file.type && file.type.includes('image')" width="22" height="22" :src="file.url" />
            <v-card min-width="22" style="height: 22px;" class="d-flex align-centre justify-center rounded" v-else elevation="0" color="primary lighten-4">
                <v-icon size="17" color="primary darken-1">{{file.type.includes('video')?'mdi-video':'mdi-file'}}</v-icon>
            </v-card>

            <div style="min-width: 0px;" class="flex-grow-1 mx-4">
                <div class="elipsis-text text-right primary--text text-body-2">
                    {{ fileName }}
                </div>
            </div>
            <div style="width: 60px;">
                <div class="text-caption text-no-wrap">{{size}}</div>
            </div>
            <div class="d-flex justify-end">
                <v-btn :disabled="downloading" :loading="downloading" height="22" width="22" small @click="downloadFile" v-if="hover || downloading" icon class="ma-2">
                    <v-icon small>mdi-download</v-icon>
                </v-btn>
            </div>
        </v-card>
    </v-hover>
</template>

<script>
import DownloadApi from '@/api/DownloadApi';
import { mapMutations } from 'vuex';

export default {
    name: "FileUpload",
    props: {
        file: Object
    },
    data(){
        return {
            downloading: false
        }
    },
    computed: {
        fileName(){
            return this.file?.url?.split('/').pop() || this.file.type;
        },

        size(){
            // perform rounding of bytes similar to Mac OS
            const bytes = this.file.size;
            const units = ['bytes', 'KB', 'MB', 'GB', 'TB', 'PB'];
            const number = Math.floor(Math.log(bytes) / Math.log(1024));
            return `${(bytes / Math.pow(1024, Math.floor(number))).toFixed(2)} ${units[number]}`;
        }
    },
    methods: {
        ...mapMutations(['showSnackBar']),
        async downloadFile(){
            try {
                this.downloading = true;
                const response = await DownloadApi.download(this.file.url);
                const blob = new Blob([response.data], { type: response.headers['content-type'] });
                const link = document.createElement('a');
                link.href = URL.createObjectURL(blob);
                
                // Extracting filename from the Content-Disposition header (optional)
                const contentDisposition = response.headers['content-disposition'];
                let filename = 'downloaded_file';
                if (contentDisposition) {
                    const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
                    const matches = filenameRegex.exec(contentDisposition);
                    if (matches != null && matches[1]) {
                        filename = matches[1].replace(/['"]/g, '');
                    }
                }
                
                link.download = filename;
                link.click();
                URL.revokeObjectURL(link.href);
            } catch (error) {
                console.error(error);
            }finally{
                this.downloading = false;
            }
        },
    }
}
</script>

<style lang="scss" scoped>
.elipsis-text{
    white-space: nowrap; /* Prevents the text from wrapping onto the next line */
    overflow: hidden; /* Hide the overflow */
    text-overflow: ellipsis; /* Display ellipsis when text overflows */
    width: 100%;
    direction: rtl;
}
.text-no-wrap{
    white-space: nowrap;
}
</style>