<template>
    <div>
        <list-items-per-day v-for="(answerPerDay, i) in answersByDate" :key="`day-group-${i}`"
            :answers="answerPerDay.answers" @playAnswerAudio="playAnswerAudio" :dateHeader="answerPerDay.date">
        </list-items-per-day>
    </div>
</template>

<script>
import ListItemsPerDay from './ListItemsPerDay.vue';
import dayjs from 'dayjs';
import { mapMutations } from 'vuex';

export default {
    name: "AudioListItems",
    components: { ListItemsPerDay },
    computed: {
        audioAnswers() {
            const answers = this.answers.map((answer, i) => ({ ...answer, position: i + 1 }));
            return answers.filter(answer => answer.file_url && typeof answer.file_url === 'string' && answer.file_url !== '');
        },
        answersByDate() {
            const answers = this.answers.map((answer, i) => ({ ...answer, position: i + 1 }));
            const groupedAnswers = answers.group(({ created_at }) => dayjs(created_at).format('YYYY-MM-DD'));
            return Object.keys(groupedAnswers).map((dateString) => {
                return { date: dayjs(dateString).format('dddd, MMMM D, YYYY'), answers: groupedAnswers[dateString] };
            });
        }
    },
    watch: {
        // track if because of pagination the length of answers list changed
        'audioAnswers.length': function(){
            this.$store.state.survey.audioPlayer.audioList = [...this.audioAnswers];
        }
    },
    props: {
        answers: Array
    },
    methods: {
        ...mapMutations({
            setAudioPlayback: 'survey/setAudioPlayback'
        }),
        playAnswerAudio(answer) {
            this.setAudioPlayback({
                answer,
                answerList: this.audioAnswers
            });
        }
    }
};
</script>
