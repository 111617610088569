<template>
    <v-hover v-slot="{ hover }">
    <div class="d-flex mb-2">
        <v-spacer></v-spacer>
        <div class="d-flex flex-grow-1 align-center justify-end mr-2">
            <upgrade-wrapper v-if="containAudio" v-show="hover" :blocked="!enableAudioExport" >
                <v-btn :color="!enableAudioExport? 'deep-purple accent-3': 'default'" icon >
                    <v-icon @click="downloadAudio">mdi-download</v-icon>
                </v-btn>
            </upgrade-wrapper>
            <div v-if="!disableLike && (hover || liked)" style="max-width: 100px">
                <v-btn icon @click="handleLike">
                    <v-icon v-if="liked" color="red">mdi-heart</v-icon>
                    <v-icon v-else>mdi-heart-outline</v-icon>
                </v-btn>
            </div>
        </div>
            <v-card outlined max-width="80%" class="d-flex pa-4 rounded-lg flex-column">
                <div>
                    <div class="d-flex align-center">
                        <results-audio-item-player  v-if="containAudio" :answer="answer" :source="answer.file_url"></results-audio-item-player>
                        <div class="mr-2">
                            <div v-if="!containAudio">
                                <v-chip label small class="pa-1">Text Only</v-chip>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="d-flex mt-2">
                    <div v-if="answer.sentiment">
                        <div class="d-flex justify-center" style="white-space: nowrap;">
                            <v-tooltip :color="!enableSentiment?'deep-purple accent-3':'secondary'" bottom>
                                <template v-slot:activator="{ on, attrs }">
                                    <upgrade-wrapper v-if="!enableSentiment" v-bind="attrs" v-on="on" :blocked="!enableSentiment">
                                        <v-btn icon color="deep-purple accent-3">
                                            <v-icon>$sentiment</v-icon>
                                        </v-btn>
                                    </upgrade-wrapper>
                                    <v-icon v-bind="attrs" v-on="on" v-else-if="answer.sentiment === 'negative'" color="red">
                                        mdi-emoticon-frown-outline</v-icon>
                                    <v-icon v-bind="attrs" v-on="on" v-else-if="answer.sentiment === 'positive'"
                                        color="green">
                                        mdi-emoticon-outline</v-icon>
                                    <v-icon v-bind="attrs" v-on="on" v-else-if="answer.sentiment === 'neutral'">
                                        mdi-emoticon-neutral-outline</v-icon>
                                    <span class="text-body-2 grey--text text--darken-1" v-else>--</span>
                                </template>
                                <span v-if="!enableSentiment"><v-icon small color="white" left>mdi-diamond</v-icon>Upgrade</span>
                                <span v-else-if="answer.sentiment === 'neutral'">neutral</span>
                                <span v-else-if="answer.sentiment && answer.sentiment_score">
                                    {{ Math.round(Math.abs(answer.sentiment_score) * 100) + '% ' + answer.sentiment }}
                                </span>
                                <span v-else>Undefined Sentiment</span>
                            </v-tooltip>
                        </div>
                    </div>
                    <v-divider v-if="answer.sentiment" class="mx-2" width="2" vertical></v-divider>
                    <div :class="containAudio?'text-caption': 'text-body-2'">
                        <span v-if="!enableTranscript">
                            {{ formattedTranscript }}

                            <a class="text-decoration-underline deep-purple--text text--accent-3" @click="openDialog"><v-icon color="deep-purple accent-3" class="mr-1" x-small>mdi-diamond</v-icon>{{formattedTranscript? 'read more': 'transcript'}}</a>
                        </span>
                        <v-tooltip v-else-if="['processing', 're-processing'].includes(answer.status)" bottom
                            :max-width="260">
                            <template v-slot:activator="{ on, attrs }">
                                <v-chip label small color="primary lighten-2" v-bind="attrs"
                                    v-on="on">Processing</v-chip>
                            </template>
                            <span>Some responses require a little more time to process. Refresh this page or check back
                                soon 🙌</span>
                        </v-tooltip>
                        <span v-else-if="!answer.transcript"></span>
                        <template v-else>
                            <span >{{ transcript }}</span>
                            <a @click="showOriginal=!showOriginal" v-if="hasTranslation" class="text-caption grey--text pt-1 d-block">{{ showOriginal? 'View Translation' : 'View Original' }}</a>
                        </template>
                       
                    </div>
                    
                </div>
            </v-card>
            <v-avatar size="32" color="grey lighten-3" class="mt-1 ml-2">
                <v-icon v-if="!avatarUrl">mdi-account</v-icon>
                <img v-else :src="avatarUrl"/>
            </v-avatar>
        </div>
    </v-hover>
</template>

<script>
import RespondentInfo from '../RespondentInfo.vue';
import dayjs from 'dayjs';
import { mapGetters, mapMutations } from 'vuex';
import { downloadAudioFile } from '../../../utils/processAudio';
import LockedResponse from '../../../views/dashboard/voiceform/results/LockedResponse.vue';
import { toggleLikeAnswer } from '../../../api/survey';
import UpgradeWrapper from '../../UpgradeWrapper.vue';
import ResultsAudioItemPlayer from './ResultsAudioItemPlayer.vue';

export default {
    components: { RespondentInfo, LockedResponse, UpgradeWrapper, ResultsAudioItemPlayer },
    data() {
        return {
            liked: this.answer.liked || false,
            showOriginal: false,
        };
    },
    watch: {
        'answer.liked'(val) {
            this.liked = val;
        }
    },
    props: {
        answer: Object,
        disableLike: {
            type: Boolean,
            default: false,
        },
        avatarUrl: {
            type: String,
            default: null,
        },
    },
    computed: {
        ...mapGetters({
            audioPlayer: 'survey/audioPlayer',
            user: 'auth/user',
            features: 'auth/features',
        }),
        currentlyPlaying() {
            return this.audioPlayer.isPlaying && this.audioPlayer.currentAudioAnswer?.id === this.answer.id;
        },
        containAudio() {
            return !!(this.answer.file_url && typeof this.answer.file_url === 'string' && this.answer.file_url !== '');
        },
        longTranscript() {
            return this.answer.transcript && this.answer.transcript.length > 100;
        },
        formattedTranscript() {
            if(!this.enableTranscript){
                return this.answer.transcript ? this.answer.transcript.slice(0, 20)+'...': '';
            }
            return Array.from(this.answer.transcript).slice(0, 100).join('').trim() + '...';
        },
        enableAudioExport(){
            return !!this.features && this.features.audio_export
        },
        enableTranscript(){
            return (!!this.features && this.features.transcription) || !this.containAudio
        },
        enableSentiment(){
            return !!this.features && this.features.audio_analytics
        },
        hasTranslation(){
            return !!this.answer.translation
        },
        transcript(){
            return !this.showOriginal && this.answer.translation || this.answer.transcript;
        }
    },
    methods: {
        ...mapMutations({
            'openDialog': 'openDialog',
            setAudioPlayerPlaying: 'survey/setAudioPlayerPlaying'
        }),
        formattAnswerDate(dateString, dateOnly = false) {
            return dayjs(dateString).format(dateOnly ? 'YYYY-MM-DD' : 'YYYY-MM-DD hh:mma');
        },
        downloadAudio() {
            if (this.answer.file_url && this.enableAudioExport) {
                downloadAudioFile(this.answer.file_url, this.answer.id);
            }
        },
        togglePlay() {
            if (this.currentlyPlaying) {
                this.setAudioPlayerPlaying(false);
            } else {
                this.$emit('playAudio', this.answer);
            }
        },
        async handleExportToVideo(){
            this.$store.state.visualization.answer = this.answer
            this.$store.state.visualization.showExportEditorDialog = true
        },
        handleLike() {
            try {
                this.liked = !this.liked
                toggleLikeAnswer(this.answer.id, !this.answer.liked)
                this.$emit('onLikeToggle', this.answer, !this.answer.liked);
            } catch (error) {
                console.error('handleLike',error);
            }
        }
    }
};
</script>
