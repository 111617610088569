<template>
    <router-link :to="{
        name: 'dashboard.voiceform.results.respondents',
        params: {
            surveyId: this.$route.params.surveyId,
            resultId: this.answer.result_id
        }
    }" class="d-flex" target="_blank">
        <div class="text-caption text-decoration-underline"
            style="color: var(--primary)">Respondent</div>
        <v-icon x-small color="primary">mdi-open-in-new</v-icon>
    </router-link>
</template>

<script>
export default {
    name: 'RespondentInfo',
    props: {
        answer: Object
    },
};
</script>

<style scoped lang="scss">
.responder-label {
    min-width: 80px;
    color: var(--v-grey-base);
}

.responder-label + span {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
</style>
