import axios from 'axios'
export async function downloadAudioFile(url, name = 'audio') {
    // URL encode the path
    const encodedUrl = encodeURIComponent(url);
    // Set the proxy URL to your Laravel app's URL
    const proxyUrl = `${process.env.VUE_APP_APP_URL}/api/fetch?path=${encodedUrl}`;
    
    const response = await axios.get(proxyUrl, { responseType: 'arraybuffer' });
    if(response.status == 200){
        const audioBlob = new Blob([response.data], { type: 'audio/mpeg' });
        const link = document.createElement('a');
        link.href = URL.createObjectURL(audioBlob);
        link.setAttribute('download', `${name}.mp3`);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }
}
