<template>
    <v-snackbar multi-line :timeout="-1" :value="$vuetify.breakpoint.xs && showSmallDeviceAlert" top color="deep-purple accent-4">
        <v-icon color="white" left>mdi-monitor-cellphone-star</v-icon>
        <span class="text-body-2" style="max-width: 200px;">Your screen size is too small. Please switch to a different device like a laptop or desktop.</span>
        <template v-slot:action="{ attrs }">
            <v-btn
                text
                v-bind="attrs"
                @click="showSmallDeviceAlert = false"
                icon
            >
                <v-icon color="white">mdi-close</v-icon>
            </v-btn>
        </template>
    </v-snackbar>
</template>

<script>
export default {
    data(){
        return {
            showSmallDeviceAlert: true
        }
    }
}
</script>