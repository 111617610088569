<template>
    <v-hover v-slot="{ hover }">
        <div class="d-flex mb-2">
            <v-spacer></v-spacer>
            <div class="d-flex flex-grow-1 align-center justify-end mr-2">
                <div v-if="!disableLike && (hover || liked)" style="max-width: 100px">
                    <v-btn icon @click="handleLike">
                        <v-icon v-if="liked" color="red">mdi-heart</v-icon>
                        <v-icon v-else>mdi-heart-outline</v-icon>
                    </v-btn>
                </div>
            </div>
            <v-card outlined max-width="80%" class="d-flex rounded-lg align-top pa-4">
                <template v-if="answer">
                    <!-- answer content -->
                    <div>
                        <v-tooltip v-if="['processing', 're-processing'].includes(answer.status)" bottom
                            :max-width="260">
                            <template v-slot:activator="{ on, attrs }">
                                <v-chip label small color="primary lighten-2" v-bind="attrs"
                                    v-on="on">Processing</v-chip>
                            </template>
                            <span>Some responses require a little more time to process. Refresh this page or check back
                                soon 🙌</span>
                        </v-tooltip>
                        <matrix-transcript v-else-if="matrixType" :answer="answer"></matrix-transcript>
                        <v-sheet v-else-if="textBasedAnswer" color="primary lighten-5" rounded class="pa-1">
                            <span v-if="!longTranscript || expandTranscript" class="text-body-2" style="color: var(--v-primary-darken1)">
                                {{ transcript }}
                                <a v-if="expandTranscript" class="text-decoration-underline"
                                    @click.prevent="(expandTranscript = !expandTranscript)">collapse</a>
                            </span>
                            <span v-else class="text-body-2" style="color: var(--v-primary-darken1)">
                                {{ formattedTranscript }}
                                <a class="text-decoration-underline" @click.prevent="(expandTranscript = !expandTranscript)">read more</a>
                            </span>
                        </v-sheet>
                        <v-tooltip class="mt-1" v-else-if="contentOverflow" bottom :max-width="260">
                            <template v-slot:activator="{ on, attrs }">
                                <v-chip color="primary lighten-5" v-bind="attrs" v-on="on">
                                    <span class="answer-chip" style="color: var(--v-primary-darken1)">
                                        {{ transcript }}
                                    </span>
                                </v-chip>
                            </template>
                            <span>{{ transcript }}</span>
                        </v-tooltip>
                        <div class="text-body-2" v-else>
                            {{ transcript }}
                        </div>
                    </div>        
                </template>
            </v-card>
            <v-avatar size="32" color="grey lighten-3" class="mt-1 ml-2">
                <v-icon v-if="!avatarUrl">mdi-account</v-icon>
                <img v-else :src="avatarUrl"/>
            </v-avatar>
            
        </div>
    </v-hover>
</template>

<script>
import RespondentInfo from '../RespondentInfo.vue';
import dayjs from 'dayjs';
import LockedResponse from '../../../views/dashboard/voiceform/results/LockedResponse.vue';
import MatrixTranscript from './MatrixTranscript.vue';
import { toggleLikeAnswer } from '@/api/survey';

export default {
    name: 'RegularItem',
    props: {
        answer: Object,
        disableLike: {
            type: Boolean,
            default: false
        },
        avatarUrl: {
            type: String,
            default: null
        },
        questionProperties: {
            type: Object,
            default: null
        }
    },
    components: { RespondentInfo, LockedResponse, MatrixTranscript },
    data() {
        return {
            expandTranscript: false,
            contentOverflow: false,
            liked: this.answer.liked
        };
    },
    watch: {
        'answer.liked'(val) {
            this.liked = val;
        }
    },
    computed: {
        matrixType() {
            return this.answer.value?.type === 'matrix';
        },
        textBasedAnswer() {
            return this.answer.value?.type === 'text';
        },
        longTranscript() {
            return this.answer.transcript?.length > 100;
        },
        transcript(){
            if(this.questionProperties && this.answer?.value?.type === 'boolean'){
                if(this.answer.value.value === 'yes' && this.questionProperties.yes_label){
                    return `${this.questionProperties.yes_label} (yes)` || "Yes";
                }

                if(this.answer.value.value === 'no' && this.questionProperties.no_label){
                    return `${this.questionProperties.no_label} (no)` || "No";
                }

            }
            return this.answer.transcript;
        },
        formattedTranscript() {
            return Array.from(this.answer.transcript).slice(0, 100).join('').trim() + '...';
        }
    },
    methods: {
        formattAnswerDate(dateString, dateOnly = false) {
            return dayjs(dateString).format(dateOnly ? 'YYYY-MM-DD' : 'YYYY-MM-DD hh:mma');
        },
        handleLike() {
            try {
                this.liked = !this.liked
                toggleLikeAnswer(this.answer.id, !this.answer.liked)
                this.$emit('onLikeToggle', this.answer, !this.answer.liked);
            } catch (error) {
                console.error('handleLike',error);
            }
        }
    },
    mounted() {
        if(this.$refs.answerContent){
            this.contentOverflow = this.$refs.answerContent.clientWidth < this.$refs.answerContent.scrollWidth;
        }
    }
};
</script>

<style scoped lang="scss">
.answer-chip {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}
</style>