<template>
    <v-card>
        <v-card-title>
            <span class="text-h6">{{formHeader || 'Get in touch about our Enterprise Plan'}}</span>
            <v-spacer></v-spacer>
            <v-btn icon text @click="$emit('close')">
                <v-icon>mdi-close</v-icon>
            </v-btn>
        </v-card-title>
        <v-card-text>
            <v-form 
                ref="form" 
                v-model="valid"
                lazy-validation
            >
                <v-container no-gutters>
                    <v-row class="mx-n6">
                        <v-col class="column" cols="12" sm="6">
                            <v-text-field
                                v-model="firstName"
                                dense
                                outlined
                                label="First Name*"
                                required
                                autocomplete="given-name"
                                :rules="firstNameRules"
                                validate-on-blur
                                class="text-field"
                                name="firstName"
                            ></v-text-field>
                        </v-col>
                        <v-col class="column" cols="12" sm="6">
                            <v-text-field
                                class="text-field"
                                v-model="lastName"
                                dense
                                outlined
                                label="Last Name*"
                                required
                                autocomplete="family-name"
                                :rules="lastNameRules"
                                validate-on-blur
                            ></v-text-field>
                        </v-col>
                        <v-col class="column" cols="12">
                            <v-text-field
                                v-model="email"
                                class="text-field"
                                dense
                                outlined
                                label="Work Email*"
                                required
                                autocomplete="email"
                                :rules="emailRules"
                                validate-on-blur
                            ></v-text-field>
                        </v-col>
                        <v-col class="pt-0" cols="12">
                            <VuePhoneNumberInput
                                v-model="phone"
                                class="phone-input"
                                :dark="false" 
                                @update="onPhoneInputUpdate"
                                @phone-number-blur="handlePhoneInputBlur"
                                :preferred-countries="['US', 'CA']" 
                                color="#0066FF"
                                show-code-on-list
                                valid-color="#4CAF50"
                                error-color="#F44336"
                                clearable
                            />
                            <div style="height: 18px; pt-1">
                                <v-scroll-y-transition>
                                    <div v-if="phoneError" class="text-caption error--text">{{phoneError}}</div>
                                </v-scroll-y-transition>
                            </div>
                        </v-col>
                        <v-col class="column" cols="12">
                            <v-text-field
                                v-model="companyName"
                                dense
                                outlined
                                label="Company Name*"
                                required
                                class="text-field"
                                autocomplete="organization"
                                :rules="companyNameRules"
                                validate-on-blur
                            ></v-text-field>
                        </v-col>
                        <v-col class="column" cols="12">
                            <v-select
                                v-model="companySize"
                                dense
                                :items="['1-49', '50-249', '250-999', '1000+']"
                                label="Company Size*"
                                outlined
                                required
                                :rules="companySizeRules"
                                validate-on-blur
                                class="text-field"
                            ></v-select>
                        </v-col>
                        <v-col class="column" cols="12">
                            <v-textarea
                                v-model="notes"
                                dense
                                outlined
                                label="Add any details or questions here"
                                hide-details
                            ></v-textarea>
                        </v-col>
                    </v-row>
                </v-container>
            </v-form>
        <small>*indicates required field</small>
        </v-card-text>
        <v-card-actions class="pa-6">
            <v-spacer></v-spacer>
            <v-btn text large @click="$emit('close')">Cancel</v-btn>
            <v-btn elevation="0" :loading="loading" :disabled="loading" large color="primary" @click="submitForm">
                Submit
                <v-icon right>mdi-send</v-icon>
            </v-btn>
        </v-card-actions>
    </v-card>
</template>

<script>
import VuePhoneNumberInput from 'vue-phone-number-input';
import { mapMutations, mapGetters } from 'vuex';
import Api from '../../api/Api';
export default {
    name: 'ContactUs',
    components: {VuePhoneNumberInput},
    props: {
        formHeader: {
            type: String,
            required: false
        },
        message: {
            type: String,
            required: false
        }
    },
    data(){
        return {
            valid: true,
            loading: false,
            firstName: '',
            firstNameRules: [
                v => !!v || 'First Name is required'
            ],
            lastName: '',
            lastNameRules: [
                v => !!v || 'Last Name is required'
            ],
            email: '',
            emailRules: [
                v => !!v || 'E-mail is required',
                v => /.+@.+\..+/.test(v) || 'E-mail must be valid',
            ],
            phone: '',
            fullPhoneNumber: '',
            phoneError: '',
            phoneIsValid: true,
            companyName: '',
            companyNameRules: [
                v => !!v || 'Company Name is required'
            ],
            companySize: '',
            companySizeRules: [
                v => !!v || 'Company Size is required'
            ],
            notes: ''
        }
    },
    computed: {
        ...mapGetters({
            user: 'auth/user'
        }),
    },
    created(){
        this.firstName = this.user.first_name || ''
        this.lastName = this.user.last_name || ''
        this.email = this.user.email || ''
        this.notes = this.message || ''
    },
    methods: {
        ...mapMutations(['showSnackBar']),
        onPhoneInputUpdate(event){
            this.phoneIsValid = !event.e164 ? true : event.isValid;
            this.fullPhoneNumber = event.e164 || '';
            this.phoneError = ''
        },
        handlePhoneInputBlur(){
            if(!this.phoneIsValid){
                this.phoneError = 'Invalid phone number format'
            }
        },
        async submitForm(){
            const valid = this.$refs.form.validate()

            if(!valid){
               return
            }

            if(!this.phoneIsValid){
               return this.phoneError = 'Invalid phone number format'
            }

            try {
                this.loading = true;
                const message = {
                    name: `${this.firstName} ${this.lastName}`,
                    email: this.email,
                    phone: this.fullPhoneNumber,
                    company_name: this.companyName,
                    company_size: this.companySize,
                    message: this.notes,
                    request_from: 'Enterprise Request Contact'
                }
                await Api.contactUs(message)

                // Contact Conversion
                window.gtag && window.gtag('event', 'conversion', {'send_to': 'AW-10927426629/qHiKCIbP78IDEMWYzdoo'});

                this.$gtag.event('contact_request', {
                    'category': 'enterprise'
                });
                
                this.showSnackBar({
                    text: 'We have received your request.', 
                    color: 'success', 
                    timeout: 2000
                })

                this.$emit('submit', true)
                this.$refs.form.reset()
                this.phone = ''
                this.fullPhoneNumber= ''
                this.phoneError= ''
                this.phoneIsValid = true
               
                this.loading = false;
            } catch (error) {
                this.loading = false;
                this.showSnackBar({
                    text: 'Ops. Something went wrong. Please try to submit the form later or contact customer support.', 
                    color: 'error', 
                    timeout: 2000
                })
                console.error(error);
            }
            

        }
    }
}
</script>

<style lang="scss" scoped>
 .column{
     padding-top: 0;
     padding-bottom: 0;
 }
 .phone-input::v-deep input{
    border-color: rgba(0, 0, 0, 0.38)
 }
 .text-field::v-deep .v-text-field__details{
    margin-bottom: 4px;
 }
</style>