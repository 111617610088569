import { render, staticRenderFns } from "./GlobalDialog.vue?vue&type=template&id=277fd060&scoped=true&"
import script from "./GlobalDialog.vue?vue&type=script&lang=js&"
export * from "./GlobalDialog.vue?vue&type=script&lang=js&"
import style0 from "./GlobalDialog.vue?vue&type=style&index=0&id=277fd060&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "277fd060",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VImg } from 'vuetify/lib/components/VImg';
import { VSpacer } from 'vuetify/lib/components/VGrid';
installComponents(component, {VBtn,VCard,VDialog,VImg,VSpacer})
