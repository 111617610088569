<template>
    <v-app>
        <form-header></form-header>
        <v-main color="#FAFAFA" class="main">
            <v-container v-if="form" style="height:100%" :class="fluid?'pa-0':'pa-8'" :fluid="fluid">
                <v-card v-if="$vuetify.breakpoint.xs " class="pa-2 my-4" flat color="primary lighten-4">
                    The builder is not available on mobile devices. Please use a desktop computer.
                </v-card>
                <slot :survey="form" v-else></slot>
                <div id="voiceform-container" class="mt-1" style="float: right"></div>
            </v-container>
            <v-overlay v-else>
                <v-progress-circular
                    indeterminate
                    size="32"
                ></v-progress-circular>
            </v-overlay>
        </v-main>
        <switch-to-desctop-banner></switch-to-desctop-banner>
        <global-dialog></global-dialog>
    </v-app>
</template>

<script>
import { mapGetters } from 'vuex';
import { voiceform } from '../utils/scriptLoader';
import FormHeader from './FormHeader.vue';
import SidePanelUsage from './SidePanelUsage.vue';
import { Crisp } from "crisp-sdk-web";
import { useRoute } from 'vue-router/composables';
import { provide } from 'vue';
import useSWRV from 'swrv';
import useSwrvState from '@/utils/useSwrvState';
import fetcher from '../api/fetcher';
import SwitchToDesctopBanner from './SwitchToDesctopBanner.vue';
import GlobalDialog from '../components/GlobalDialog.vue';

const createPage = new RegExp("[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}/question/");


export default {
    props: {
        fluid: {
            type: Boolean,
            default: false
        },
    },
    components: { SidePanelUsage, FormHeader, SwitchToDesctopBanner, GlobalDialog },
    computed: {
        ...mapGetters({
            user: 'auth/user',
            survey: 'survey/survey'
        }),
    },
    methods: {
        handleVisibilityChange() {
            if (document.hidden) {
                this.unfocusTextInput();
            }
        },
        // we use this function to make sure that when user performs tabs switch, we are saving an input.
        unfocusTextInput() {
            const activeElement = document.activeElement;

            if (activeElement && activeElement.tagName === 'INPUT' && activeElement.type === 'text') {
                activeElement.blur();
            }
        },
    },
    created() {
        if (window.location.href.includes('/create') || createPage.test(window.location.href)) {
            voiceform('slider')
        } else {
            voiceform('side-tab');
        }

        Crisp.chat.show();

        this.handleVisibilityChange = this.handleVisibilityChange.bind(this);
        document.addEventListener('visibilitychange', this.handleVisibilityChange);
    },

    destroyed() {
      document.removeEventListener('visibilitychange', this.handleVisibilityChange);
    },
    setup(){
        const route = useRoute();

        const { data, error, isValidating, mutate } = useSWRV(()=>`/api/forms/${route.params.surveyId}`, fetcher, {
            revalidateDebounce: 100,
        });
        const { state, STATES } = useSwrvState(data, error, isValidating)

        // providing questions to children
        provide('survey', data);
        provide('refreshSurvey', mutate);

        return {state, STATES, form:data||null, error, isValidating, refreshForm:mutate };
    }
}
</script>

<style lang="scss">
    :root {
        --overflow: unset;
    }
</style>

<style scoped>
.main{
    background-color: #FAFAFA;
}
</style>