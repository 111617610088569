<template>
    <v-app-bar v-if="survey" clipped-left clipped-right style="border-bottom: 1px solid #0000003B !important;" class="header" height="64" color="primary lighten-5" outlined elevation="0" app>
        <div style="min-width: 320px" class="d-flex align-center">
            <router-link :to="{path: '/'}">
                <img src="https://cdn.voiceform.com/media/voiceform_icon_blue.svg" style="height: 32px; width: 32px" height="32px"/>
            </router-link>
            <v-divider class="mx-4 my-2" vertical></v-divider>
            <form-name></form-name>
        </div>
        <v-tabs class="mb-0" v-if="survey" centered color="primary" v-model="activeTab">
            <v-tab 
                v-for="tab in tabs"
                class="tab"
                :to="{
                    name: tab.routeName,
                    params: {
                        surveyId: survey.id
                    }
                }"
                :key="tab.label"
            >
                <v-icon color="primary" left v-text="tab.icon"></v-icon>
                {{tab.label}}
            </v-tab>
        </v-tabs>
        <v-spacer></v-spacer>
        <div style="min-width: 320px" class="d-flex justify-end align-center">
            <v-btn :dark="showLimitedTimeOffer" :outlined="!showLimitedTimeOffer" :to="{ name: 'dashboard.upgrade'}" :color="showLimitedTimeOffer?'deep-orange lighten-1':'primary'" elevation="0">{{showLimitedTimeOffer?`Offer expires ${offerExpiresIn}`:"View Plans"}}</v-btn>
            <v-divider class="mx-4 my-2" vertical></v-divider>
            <user-profile-dropdown></user-profile-dropdown>
        </div>
    </v-app-bar>
</template>

<script>
import { mapGetters } from 'vuex'
import FormName from './FormName.vue'
import UserProfileDropdown from './UserProfileDropdown.vue'
export default {
    data(){
        return {
            tabs: [
                {
                    label: 'Create', 
                    routeName: 'dashboard.voiceform.show',
                    icon: 'mdi-pencil-outline'
                },
                {
                    label: 'Connect', 
                    routeName: 'dashboard.voiceform.connect',
                    icon: 'mdi-connection'
                },
                {
                    label: 'Share', 
                    routeName: 'dashboard.voiceform.share',
                    icon: 'mdi-share-variant-outline'
                },
                {
                    label: 'Results',
                    routeName: 'dashboard.voiceform.results',
                    icon: 'mdi-check-all'
                },
            ],
            activeTab: 2
        }

    },
    inject: ['survey'],
    components: { UserProfileDropdown, FormName },
    computed:{
        ...mapGetters({
            user: 'auth/user',
            showLimitedTimeOffer: 'auth/showLimitedTimeOffer'
        }),
        surveyId(){
            return this.survey && this.survey.id
        },
        offerExpiresIn(){
            if(this.showLimitedTimeOffer ){
                return this.user.limited_time_offer && this.$date(this.user.limited_time_offer).fromNow()
            }
            return ''
        }
    }
}
</script>
<style lang="scss" scoped>
.header{
    border-bottom: 1px solid #0000003B !important;
}
.tab{
    color: var(--v-primary-base) !important;
    text-transform: capitalize;
}
</style>